/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import * as Segment from './segment'

export interface AccountClicked {
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The number of the page in the given context. Samples: 1, 3, 5
	 */
	page_number: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CompaniesItem {
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	company_ids: string | null
}
export interface PermissionsItem {
	/**
	 * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
	 */
	permissions_groups: string | null
}
export interface SupportedCountryItem {
	/**
	 * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	supported_countries: string | null
}
export interface AccountDeselection {
	/**
	 * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
	 */
	account_remove_permission: boolean | null
	/**
	 * The number of associated accounts. Sample: '1, 3, 5'
	 */
	associated_accounts: number | null
	/**
	 * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	block_permission: boolean | null
	/**
	 * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	companies: CompaniesItem[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	edit_permission: boolean | null
	/**
	 * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	permissions: PermissionsItem[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	supported_country: SupportedCountryItem[] | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface AccountDetailsViewed {
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The number of associated users. Sample: '1, 3, 5'
	 */
	associated_users: number | null
	/**
	 * The button 'change settings' is enable (true or false)
	 */
	change_settings_permission: boolean | null
	/**
	 * The button 'add' is enable (true or false)
	 */
	invite_user_permission: boolean | null
	/**
	 * The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer
	 */
	purchase_experience: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AccountLinkingExecutionStarted {
	/**
	 * country of the uploaded file
	 */
	file_country: string | null
	/**
	 * The file Id generated in the service. Sample. 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	file_id: string | null
	/**
	 * status of the uploaded file
	 */
	file_status: string | null
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AccountLinkingValidationStarted {
	/**
	 * country of the uploaded file
	 */
	file_country: string | null
	/**
	 * The file Id generated in the service. Sample. 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	file_id: string | null
	/**
	 * status of the uploaded file
	 */
	file_status: string | null
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AccountListSearched {
	/**
	 * The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer
	 */
	purchase_experience: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
	 */
	search_criteria: string
}
export interface AccountInformationItem {
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The user purchase experience in the given context. Samples: Bees Link, Bees Customer
	 */
	purchase_experience: string | null
	/**
	 * The number of associated users. Sample: '1, 3, 5'
	 */
	user_associated: number | null
}
export interface AccountListViewed {
	/**
	 * The array of account information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	account_information: AccountInformationItem[] | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
	 */
	search_criteria: string
	/**
	 * The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'
	 */
	wholesaler_filter: string | null
}
export interface AccountRemovalCanceled {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AccountRemovalStarted {
	/**
	 * The number of associated accounts. Sample: '1, 3, 5'
	 */
	associated_accounts: number | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The number of selected accounts. Sample: '1, 3, 5'
	 */
	selected_accounts: number | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
}
export interface CompaniesItem1 {
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	company_ids: string | null
}
export interface PermissionsItem1 {
	/**
	 * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
	 */
	permissions_groups: string | null
}
export interface SupportedCountryItem1 {
	/**
	 * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	supported_countries: string | null
}
export interface AccountRemovalSubmissionResult {
	/**
	 * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
	 */
	account_remove_permission: boolean | null
	/**
	 * The number of associated accounts. Sample: '1, 3, 5'
	 */
	associated_accounts: number | null
	/**
	 * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	block_permission: boolean | null
	/**
	 * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	companies: CompaniesItem1[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	edit_permission: boolean | null
	/**
	 * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	permissions: PermissionsItem1[] | null
	/**
	 * The number of fail removal. Sample: 1,2,3,4
	 */
	removal_fail_count: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The number of selected accounts. Sample: '1, 3, 5'
	 */
	selected_accounts: number | null
	/**
	 * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	supported_country: SupportedCountryItem1[] | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface AccountRemovalSubmitted {
	/**
	 * The number of associated accounts. Sample: '1, 3, 5'
	 */
	associated_accounts: number | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The number of selected accounts. Sample: '1, 3, 5'
	 */
	selected_accounts: number | null
	/**
	 * The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
	 */
	user_app: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
}
export interface CompaniesItem2 {
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	company_ids: string | null
}
export interface PermissionsItem2 {
	/**
	 * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
	 */
	permissions_groups: string | null
}
export interface SupportedCountryItem2 {
	/**
	 * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	supported_countries: string | null
}
export interface AccountSelection {
	/**
	 * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
	 */
	account_remove_permission: boolean | null
	/**
	 * The number of associated accounts. Sample: '1, 3, 5'
	 */
	associated_accounts: number | null
	/**
	 * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	block_permission: boolean | null
	/**
	 * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	companies: CompaniesItem2[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	edit_permission: boolean | null
	/**
	 * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	permissions: PermissionsItem2[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	supported_country: SupportedCountryItem2[] | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface AddedUserIdsItem {
	/**
	 * The added user ids. Sample. 6096c481; 9238748932; 309128932
	 */
	added_user_id: string | null
}
export interface AddAdminAccess {
	/**
	 * The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	account_country: string | null
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The array of added user ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	added_user_ids: AddedUserIdsItem[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AddNewUserStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AddScopeToRole {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	role_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AddScopeToRoleCanceled {
	/**
	 * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
	 */
	cancel_button: boolean | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ScopeNamesItem {
	/**
	 * The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
	 */
	scope_name: string | null
}
export interface AddScopeToRoleSubmissionResult {
	/**
	 * The number of fails scope addition. Sample: '1, 3, 5'
	 */
	add_scopes_fail: number | null
	/**
	 * The number of added scopes. Sample: '1, 3, 5'
	 */
	add_scopes_success: number | null
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	role_name: string | null
	/**
	 * The array of scope names. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	scope_names: ScopeNamesItem[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AddScopeToRoleSubmitted {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	role_name: string | null
	/**
	 * The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	scopes_added: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AdminUserCategorySelected {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AdminUserInvitationCanceled {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AdminUserInvitationFinished {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AdminUserInvitationRestarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CountriesItem {
	/**
	 * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	supported_country: string | null
}
export interface DeliveryItem {
	/**
	 * The delivery center id generated. Sample: BK32
	 */
	delivery_center_id: string | null
}
export interface EmailItem {
	/**
	 * The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	email_list: string | null
}
export interface LogisticItem {
	/**
	 * The Logistic operator ID. Sample: 460751
	 */
	logistic_operator_id: string | null
}
export interface PermissionsItem3 {
	/**
	 * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
	 */
	permissions_groups: string | null
}
export interface AdminUserInvitationResult {
	/**
	 * The number of fails associations. Sample: 1,2,3,4
	 */
	association_fail_count: number | null
	/**
	 * The number of success associations. Sample: 1,2,3,4
	 */
	association_success_count: number | null
	/**
	 * The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	countries: CountriesItem[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	delivery: DeliveryItem[] | null
	/**
	 * The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	email: EmailItem[] | null
	/**
	 * The number of fails invitations. Sample: 1,2,3,4
	 */
	invitation_fail_count: number | null
	/**
	 * The number of success invitations. Sample: 1,2,3,4
	 */
	invitation_success_count: number | null
	/**
	 * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	logistic: LogisticItem[] | null
	/**
	 * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	permissions: PermissionsItem3[] | null
	/**
	 * The Route ID used for force users. Sample: 20210111-100902-1841701
	 */
	route_id: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CompaniesItem3 {
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	company_ids: string | null
}
export interface CountriesItem1 {
	/**
	 * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	supported_country: string | null
}
export interface DeliveryItem1 {
	/**
	 * The delivery center id generated. Sample: BK32
	 */
	delivery_center_id: string | null
}
export interface EmailItem1 {
	/**
	 * The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	email_list: string | null
}
export interface LogisticItem1 {
	/**
	 * The Logistic operator ID. Sample: 460751
	 */
	logistic_operator_id: string | null
}
export interface PermissionsItem4 {
	/**
	 * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
	 */
	permissions_groups: string | null
}
export interface AdminUserInvitationSubmitted {
	/**
	 * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	companies: CompaniesItem3[] | null
	/**
	 * The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	countries: CountriesItem1[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	delivery: DeliveryItem1[] | null
	/**
	 * The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	email: EmailItem1[] | null
	/**
	 * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	logistic: LogisticItem1[] | null
	/**
	 * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	permissions: PermissionsItem4[] | null
	/**
	 * The Route ID used for force users. Sample: 20210111-100902-1841701
	 */
	route_id: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CompaniesItem4 {
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	company_ids: string | null
}
export interface PermissionsItem5 {
	/**
	 * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
	 */
	permissions_groups: string | null
}
export interface SupportedCountryItem3 {
	/**
	 * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	supported_countries: string | null
}
export interface AllAccountSelection {
	/**
	 * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
	 */
	account_remove_permission: boolean | null
	/**
	 * The number of associated accounts. Sample: '1, 3, 5'
	 */
	associated_accounts: number | null
	/**
	 * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	block_permission: boolean | null
	/**
	 * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	companies: CompaniesItem4[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	edit_permission: boolean | null
	/**
	 * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	permissions: PermissionsItem5[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	supported_country: SupportedCountryItem3[] | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface AllUserDeselection {
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The number of associated users. Sample: '1, 3, 5'
	 */
	associated_users: number | null
	/**
	 * The button 'change settings' is enable (true or false)
	 */
	change_settings_permission: boolean | null
	/**
	 * The button 'add' is enable (true or false)
	 */
	invite_user_permission: boolean | null
	/**
	 * The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer
	 */
	purchase_experience: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ApplicationsItem {
	/**
	 * The value chosen to filter the list. Which variable the user is using to perform the filtering action. Permitted values: All, admin portal, cmsadmin, deliveradmin, force, oncustomer, social, supplier
	 */
	app_filter: string | null
}
export interface ApplicationFiltered {
	/**
	 * The array of applications. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	applications: ApplicationsItem[] | null
	/**
	 * The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country', 'Application'
	 */
	filter_criteria: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values: USERS
	 */
	list_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ApplicationSelected {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The number of apps available to be selected. Sample: '1, 3, 5'
	 */
	applications_available: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface BulkActionsViewed {
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ChangePurchaseExperienceStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ChartNavigation {
	/**
	 * The manager redirection filed. Sample: True or False
	 */
	manager_redirection: boolean | null
	/**
	 * The reporter direction field Sample: True or False.
	 */
	reporter_redirection: boolean | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CopyCustomerRegistrationLink {
	/**
	 * The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	account_country: string | null
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	new_user_email: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CopyUserRegistrationLink {
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
	 */
	user_app: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
}
export interface CountryFiltered {
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
	 */
	user_type: string | null
}
export interface CustomerUserInvitationCanceled {
	/**
	 * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
	 */
	cancel_button: boolean | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CustomerUserInvitationFinished {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CustomerUserInvitationRestarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CustomerUserInvitationResult {
	/**
	 * The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	account_country: string | null
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	new_user_email: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The user was associated or not. It's a boolean. If trigarred, set True. If not, set False.
	 */
	user_associated: boolean | null
	/**
	 * The user was invited or not. It's a boolean. If triggarred, set True. If not, set False.
	 */
	user_invited: boolean | null
}
export interface CustomerUserInvitationSubmitted {
	/**
	 * The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	account_country: string | null
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	new_user_email: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface DescriptionEdited {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	entity_name: string | null
	/**
	 * The value chosen to search.  Samples: 'Group','Role', 'Scope'
	 */
	entity_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface EntitySearched {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	entity_name: string | null
	/**
	 * The value chosen to search.  Samples: 'Group','Role', 'Scope'
	 */
	entity_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ExportAccountList {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	wholesaler_country: string
	/**
	 * The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'
	 */
	wholesaler_filter: string | null
}
export interface ExportReportDownloaded {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	wholesaler_country: string
	/**
	 * The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'
	 */
	wholesaler_filter: string | null
}
export interface FileDownloaded {
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface FileUploaded {
	/**
	 * Selecting the file by clicking on 'Browse File' or 'Droping it". It's a boolean. If triggared, set True. If not, set False
	 */
	browse_button: boolean | null
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ManagePermissionCanceled {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ManagePermissionStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AdminPermissionItem {
	/**
	 * The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
	 */
	admin_permissions: string | null
}
export interface ManagerPermissionSubmitted {
	/**
	 * The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	admin_affiliation: string | null
	/**
	 * The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	admin_permission: AdminPermissionItem[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	user_affiliation: string | null
	/**
	 * The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
	 */
	user_app: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface NewUploadCanceled {
	/**
	 * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
	 */
	cancel_button: boolean | null
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface NewUploadStarted {
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface NewUploadSubmissionResult {
	/**
	 * The date of the upload. Sample: January 13, 2023 9:34 AM
	 */
	creation_date: string | null
	/**
	 * The description of the file uploaded. Sample: "Add permission for the user"
	 */
	description: string | null
	/**
	 * The name of the file uploaded. Sample: "e9429287-86d9-417e-b700-6de0ac6a0199-1673298943073.csv"
	 */
	file_name: string | null
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The status in the given context. Permitted value: Failed, Success, Partial Success
	 */
	status_upload: string | null
	/**
	 * The title of the file uploaded. Sample: "permission_manager.csv"
	 */
	title_name: string | null
	/**
	 * The URL to dowload the file processed. Sample: "https://beesdevfilemgmt.blob.core.windows.net/files-br/identity-management-update-users_output-e9429287-86d9-417e-b700-6de0ac6a0199-1673298943073.csv?sig=jx%2FmYU8MPHJIiSOgm44K5xkU%2BfHJsczYlkHtTkroc%2FY%3D&se=2023-01-17T14%3A48%3A18Z&sv=2015-04-05&sp=r&sr=b"
	 */
	url_download: string | null
	/**
	 * The vendor ID selected to manage customer users. Sample: 20210111-100902-1841701
	 */
	vendor_id: string | null
}
export interface NewUploadSubmitted {
	/**
	 * The description of the file uploaded. Sample: "Add permission for the user"
	 */
	description: string | null
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * count CSV rows with data
	 */
	rows_number: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The title of the file uploaded. Sample: "permission_manager.csv"
	 */
	title_name: string | null
	/**
	 * The vendor ID selected to manage customer users. Sample: 20210111-100902-1841701
	 */
	vendor_id: string | null
}
export interface PageSelected {
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The number of the page in the given context. Samples: 1, 3, 5
	 */
	page_number: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface PageViewed {
	/**
	 * Action type is a property to store actions users perform on the events. Eg. Open, Close, Success, Dismiss, Cloned, etc.
	 */
	action_type?: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface PermissionsViewed {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface PurchaseExperienceSubmitted {
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The number of associated users. Sample: '1, 3, 5'
	 */
	associated_users: number | null
	/**
	 * The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer
	 */
	purchase_experience: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RecentActivitiesSelected {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The value chosen to filter the list. Which variable the user is using to perform the tab selection. example: "completed", "pending"
	 */
	tab_name: string | null
}
export interface RecentActivitiesViewed {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RefreshedPage {
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RemovedUserIdsItem {
	/**
	 * The removed user ids. Sample. 6096c481; 9238748932; 309128932
	 */
	removed_user_id: string | null
}
export interface RemoveAdminAccess {
	/**
	 * The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	account_country: string | null
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The array of removed user ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	removed_user_ids: RemovedUserIdsItem[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RemoveScopeFromRole {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	role_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RemoveScopeFromRoleCanceled {
	/**
	 * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
	 */
	cancel_button: boolean | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ScopeNamesItem1 {
	/**
	 * The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
	 */
	scope_name: string | null
}
export interface RemoveScopeFromRoleSubmissionResult {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The number of fails scope addition. Sample: '1, 3, 5'
	 */
	remove_scopes_fail: number | null
	/**
	 * The number of added scopes. Sample: '1, 3, 5'
	 */
	remove_scopes_success: number | null
	/**
	 * The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	role_name: string | null
	/**
	 * The array of scope names. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	scope_names: ScopeNamesItem1[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RemoveScopeFromRoleSubmitted {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	role_name: string | null
	/**
	 * The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	scopes_removed: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ReporterDetailsRedirection {
	/**
	 * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	reporter_country: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	reporter_email: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ReporterEmailCopy {
	/**
	 * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	reporter_country: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	reporter_email: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ResendCustomerInvitation {
	/**
	 * The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	account_country: string | null
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	new_user_email: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ResendUserInvitation {
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
	 */
	user_app: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
}
export interface RoleCreationCanceled {
	/**
	 * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
	 */
	cancel_button: boolean | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RoleCreationResult {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The role was created or not. It's a boolean. If trigarred, set True. If not, set False.
	 */
	role_creation: boolean | null
	/**
	 * The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	role_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RoleCreationStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RoleCreationSubmitted {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
	 */
	role_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RoleDeletion {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
	 */
	scope_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ScopeCreationCanceled {
	/**
	 * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
	 */
	cancel_button: boolean | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ScopeCreationResult {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The scope was created or not. It's a boolean. If trigarred, set True. If not, set False.
	 */
	scope_creation: boolean | null
	/**
	 * The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
	 */
	scope_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ScopeCreationStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ScopeCreationSubmitted {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
	 */
	scope_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface ScopeDeletion {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
	 */
	scope_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface TabSelected {
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface TemplateDownloadedHome {
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface TemplateDownloadedModal {
	/**
	 * The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
	 */
	file_type: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The vendor ID selected to manage customer users. Sample: 20210111-100902-1841701
	 */
	vendor_id: string | null
}
export interface UserBlockCanceled {
	/**
	 * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
	 */
	cancel_button: boolean | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AdminPermissionItem1 {
	/**
	 * The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
	 */
	admin_permissions: string | null
}
export interface UserBlocked {
	/**
	 * The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	admin_affiliation: string | null
	/**
	 * The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	admin_permission: AdminPermissionItem1[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	user_affiliation: string | null
	/**
	 * The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
	 */
	user_app: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface UserChartViewed {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserClicked {
	/**
	 * The number of pages loaded. Samples: 10, 15, 30
	 */
	clicked_item_position: number | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AdminPermissionItem2 {
	/**
	 * The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
	 */
	admin_permissions: string | null
}
export interface UserDetailsViewed {
	/**
	 * The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	admin_affiliation: string | null
	/**
	 * The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	admin_permission: AdminPermissionItem2[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	user_affiliation: string | null
	/**
	 * The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
	 */
	user_app: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface UserEditingCanceled {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserEditingStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CompaniesItem5 {
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	company_ids: string | null
}
export interface DdcIdsItem {
	/**
	 * The DDC id generated. Sample: BK32
	 */
	ddc_id: string | null
}
export interface DeliveryItem2 {
	/**
	 * The delivery center id generated. Sample: BK32
	 */
	delivery_center_id: string | null
}
export interface LogisticItem2 {
	/**
	 * The Logistic operator ID. Sample: 460751
	 */
	logistic_operator_id: string | null
}
export interface PermissionsItem6 {
	/**
	 * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
	 */
	permissions_groups: string | null
}
export interface SupportedCountryItem4 {
	/**
	 * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	supported_countries: string | null
}
export interface UserEditingSubmissionResult {
	/**
	 * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
	 */
	account_remove_permission: boolean | null
	/**
	 * The number of associated accounts. Sample: '1, 3, 5'
	 */
	associated_accounts: number | null
	/**
	 * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	block_permission: boolean | null
	/**
	 * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	companies: CompaniesItem5[] | null
	/**
	 * The number of fails company associantion. Sample: 1,2,3,4
	 */
	company_association_fail_count: number | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The number of fails supported country association. Sample: 1,2,3,4
	 */
	country_association_fail_count: number | null
	/**
	 * The array of ddcs Ids. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	ddc_ids: DdcIdsItem[] | null
	/**
	 * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	delivery: DeliveryItem2[] | null
	/**
	 * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	edit_permission: boolean | null
	/**
	 * The number of fails group associations. Sample: 1,2,3,4
	 */
	group_association_fail_count: number | null
	/**
	 * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	logistic: LogisticItem2[] | null
	/**
	 * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	permissions: PermissionsItem6[] | null
	/**
	 * The Route ID used for force users. Sample: 20210111-100902-1841701
	 */
	route_id: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	supported_country: SupportedCountryItem4[] | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface CompaniesItem6 {
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	company_ids: string | null
}
export interface DeliveryItem3 {
	/**
	 * The delivery center id generated. Sample: BK32
	 */
	delivery_center_id: string | null
}
export interface LogisticItem3 {
	/**
	 * The Logistic operator ID. Sample: 460751
	 */
	logistic_operator_id: string | null
}
export interface PermissionsItem7 {
	/**
	 * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
	 */
	permissions_groups: string | null
}
export interface SupportedCountryItem5 {
	/**
	 * The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	supported_countries: string | null
}
export interface UserEditingSubmitted {
	/**
	 * The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
	 */
	account_remove_permission: boolean | null
	/**
	 * The number of associated accounts. Sample: '1, 3, 5'
	 */
	associated_accounts: number | null
	/**
	 * The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	block_permission: boolean | null
	/**
	 * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	companies: CompaniesItem6[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	delivery: DeliveryItem3[] | null
	/**
	 * The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
	 */
	edit_permission: boolean | null
	/**
	 * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	logistic: LogisticItem3[] | null
	/**
	 * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	permissions: PermissionsItem7[] | null
	/**
	 * The Route ID used for force users. Sample: 20210111-100902-1841701
	 */
	route_id: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	supported_country: SupportedCountryItem5[] | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface UserInfoCopied {
	/**
	 * 'Copied value in the given context. Sample: 'ID' , 'phone', 'email'
	 */
	copied_criteria: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserInvitationCanceled {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserInvitationCategorySelected {
	/**
	 * The invitation category selected. Permitted Values.ONE or FORCE
	 */
	invitation_category: string
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserInvitationFinished {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserInvitationRestarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserInvitationResult {
	/**
	 * The number of fails associations. Sample: 1,2,3,4
	 */
	association_fail_count: number | null
	/**
	 * The number of success associations. Sample: 1,2,3,4
	 */
	association_success_count: number | null
	/**
	 * The invitation category selected. Permitted Values.ONE or FORCE
	 */
	invitation_category: string
	/**
	 * The number of fails invitations. Sample: 1,2,3,4
	 */
	invitation_fail_count: number | null
	/**
	 * The number of success invitations. Sample: 1,2,3,4
	 */
	invitation_success_count: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserInvitationStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface CompaniesItem7 {
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	company_ids: string | null
}
export interface CountriesItem2 {
	/**
	 * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	supported_country: string | null
}
export interface DeliveryItem4 {
	/**
	 * The delivery center id generated. Sample: BK32
	 */
	delivery_center_id: string | null
}
export interface LogisticItem4 {
	/**
	 * The Logistic operator ID. Sample: 460751
	 */
	logistic_operator_id: string | null
}
export interface NewUserInfoItem {
	/**
	 * The manager email in a given context. Permitted values. Samples. isabela.nogueira@ab-inbev.com
	 */
	manager_email: string | null
	/**
	 * The list of supervisors emails in a given context. Permitted values. Samples. isabela.nogueira@ab-inbev.com, isabela@gmail.com
	 */
	supervisors_emails: string | null
	/**
	 * The user email in a given context. Permitted values. Samples. isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
}
export interface PermissionsItem8 {
	/**
	 * Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
	 */
	permissions_groups: string | null
}
export interface UserInvitationSubmitted {
	/**
	 * The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	companies: CompaniesItem7[] | null
	/**
	 * The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	countries: CountriesItem2[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	delivery: DeliveryItem4[] | null
	/**
	 * The invitation category selected. Permitted Values.ONE or FORCE
	 */
	invitation_category: string
	/**
	 * The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	logistic: LogisticItem4[] | null
	/**
	 * The array of new user information as email, manager and supervisor
	 */
	new_user_info?: NewUserInfoItem[] | null
	/**
	 * The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	permissions: PermissionsItem8[] | null
	/**
	 * The Route ID used for force users. Sample: 20210111-100902-1841701
	 */
	route_id: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserInvitationViewed {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserListActions {
	/**
	 * The action in the given context. Permitted Values: User Details, Remove User
	 */
	action_selected: string | null
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'
	 */
	group_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: 'isabela@gmail.com'
	 */
	user_selected: string | null
}
export interface UserListFiltered {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The country in the given context. Permitted values: All,Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	country_filtered: string | null
	/**
	 * The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'
	 */
	group_name: string | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface InformationItem {
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked
	 */
	user_status: string | null
}
export interface UserListPagination {
	/**
	 * The number of pages loaded. Samples: 10, 15, 30
	 */
	clicked_item_position: number | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	information: InformationItem[] | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
	 */
	search_criteria: string
}
export interface UserListSearched {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'
	 */
	group_name: string | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
	 */
	search_criteria: string
}
export interface InformationItem1 {
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked
	 */
	user_status: string | null
}
export interface UserListViewed {
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	information: InformationItem1[] | null
	/**
	 * The number of items listed in the given context. Samples: 3, 8, 15
	 */
	listed_items: number | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
	 */
	search_criteria: string
	/**
	 * The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
	 */
	user_type: string | null
}
export interface UserRemovalStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface RemovedUserIdsItem1 {
	/**
	 * The removed user ids. Sample. 6096c481; 9238748932; 309128932
	 */
	removed_user_id: string | null
}
export interface UserRemovalSubmitted {
	/**
	 * The account ID in a given context. Permitted values. Samples: 'H8999'
	 */
	account_id: string | null
	/**
	 * The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
	 */
	account_name: string | null
	/**
	 * The array of removed user ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	removed_user_ids: RemovedUserIdsItem1[] | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserSearchCriteria {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
	 */
	search_criteria: string
	/**
	 * The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
	 */
	user_type: string | null
}
export interface UserTypeSelected {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
	 */
	user_type: string | null
}
export interface UserUnblockCanceled {
	/**
	 * Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
	 */
	cancel_button: boolean | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AdminPermissionItem3 {
	/**
	 * The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
	 */
	admin_permissions: string | null
}
export interface UserUnblocked {
	/**
	 * The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	admin_affiliation: string | null
	/**
	 * The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	admin_permission: AdminPermissionItem3[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	user_affiliation: string | null
	/**
	 * The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
	 */
	user_app: string | null
	/**
	 * The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
	 */
	user_email: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
	/**
	 * The user phone number in the given context. Samples: +5519999841766
	 */
	user_phone: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_status: string | null
}
export interface UserViewed {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserExtractionStarted {
	/**
	 * The value chosen to start the experience. Samples: 'PIM' , 'Identity'
	 */
	application_selected: string | null
	/**
	 * The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'
	 */
	group_name: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserInformationEditCanceled {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface UserInformationEditStarted {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
}
export interface AdminPermissionItem4 {
	/**
	 * The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
	 */
	admin_permissions: string | null
}
export interface UserInformationEditSubmitted {
	/**
	 * The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	admin_affiliation: string | null
	/**
	 * The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
	 */
	admin_permission: AdminPermissionItem4[] | null
	/**
	 * To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
	 */
	country: string | null
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
	 */
	user_affiliation: string | null
	/**
	 * The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
	 */
	user_app: string | null
	/**
	 * The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
	 */
	user_id: string | null
}
export interface WholesalerFiltered {
	/**
	 * The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
	 */
	screen_name: string | null
	/**
	 * The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
	 */
	wholesaler_country: string
	/**
	 * The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'
	 */
	wholesaler_filter: string | null
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef AccountClicked
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {number | null} page_number - The number of the page in the given context. Samples: 1, 3, 5
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CompaniesItem
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AccountDeselection
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {SupportedCountryItem[] | null} supported_country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef AccountDetailsViewed
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {number | null} associated_users - The number of associated users. Sample: '1, 3, 5'
 * @property {boolean | null} change_settings_permission - The button 'change settings' is enable (true or false)
 * @property {boolean | null} invite_user_permission - The button 'add' is enable (true or false)
 * @property {string | null} purchase_experience - The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AccountLinkingExecutionStarted
 * @property {string | null} file_country - country of the uploaded file
 * @property {string | null} file_id - The file Id generated in the service. Sample. 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} file_status - status of the uploaded file
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AccountLinkingValidationStarted
 * @property {string | null} file_country - country of the uploaded file
 * @property {string | null} file_id - The file Id generated in the service. Sample. 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} file_status - status of the uploaded file
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AccountListSearched
 * @property {string | null} purchase_experience - The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
 */
/**
 * @typedef AccountInformationItem
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {string | null} purchase_experience - The user purchase experience in the given context. Samples: Bees Link, Bees Customer
 * @property {number | null} user_associated - The number of associated users. Sample: '1, 3, 5'
 */
/**
 * @typedef AccountListViewed
 * @property {AccountInformationItem[] | null} account_information - The array of account information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
 * @property {string | null} wholesaler_filter - The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'
 */
/**
 * @typedef AccountRemovalCanceled
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AccountRemovalStarted
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {number | null} selected_accounts - The number of selected accounts. Sample: '1, 3, 5'
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CompaniesItem1
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem1
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem1
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AccountRemovalSubmissionResult
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem1[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem1[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} removal_fail_count - The number of fail removal. Sample: 1,2,3,4
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {number | null} selected_accounts - The number of selected accounts. Sample: '1, 3, 5'
 * @property {SupportedCountryItem1[] | null} supported_country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef AccountRemovalSubmitted
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {number | null} selected_accounts - The number of selected accounts. Sample: '1, 3, 5'
 * @property {string | null} user_app - The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CompaniesItem2
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem2
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem2
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AccountSelection
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem2[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem2[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {SupportedCountryItem2[] | null} supported_country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef AddedUserIdsItem
 * @property {string | null} added_user_id - The added user ids. Sample. 6096c481; 9238748932; 309128932
 */
/**
 * @typedef AddAdminAccess
 * @property {string | null} account_country - The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {AddedUserIdsItem[] | null} added_user_ids - The array of added user ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AddNewUserStarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AddScopeToRole
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} role_name - The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AddScopeToRoleCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ScopeNamesItem
 * @property {string | null} scope_name - The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
 */
/**
 * @typedef AddScopeToRoleSubmissionResult
 * @property {number | null} add_scopes_fail - The number of fails scope addition. Sample: '1, 3, 5'
 * @property {number | null} add_scopes_success - The number of added scopes. Sample: '1, 3, 5'
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} role_name - The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {ScopeNamesItem[] | null} scope_names - The array of scope names. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AddScopeToRoleSubmitted
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} role_name - The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} scopes_added - The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AdminUserCategorySelected
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AdminUserInvitationCanceled
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AdminUserInvitationFinished
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AdminUserInvitationRestarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CountriesItem
 * @property {string | null} supported_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 */
/**
 * @typedef DeliveryItem
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef EmailItem
 * @property {string | null} email_list - The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 */
/**
 * @typedef LogisticItem
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef PermissionsItem3
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef AdminUserInvitationResult
 * @property {number | null} association_fail_count - The number of fails associations. Sample: 1,2,3,4
 * @property {number | null} association_success_count - The number of success associations. Sample: 1,2,3,4
 * @property {CountriesItem[] | null} countries - The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {DeliveryItem[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {EmailItem[] | null} email - The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} invitation_fail_count - The number of fails invitations. Sample: 1,2,3,4
 * @property {number | null} invitation_success_count - The number of success invitations. Sample: 1,2,3,4
 * @property {LogisticItem[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {PermissionsItem3[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CompaniesItem3
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CountriesItem1
 * @property {string | null} supported_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 */
/**
 * @typedef DeliveryItem1
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef EmailItem1
 * @property {string | null} email_list - The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 */
/**
 * @typedef LogisticItem1
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef PermissionsItem4
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef AdminUserInvitationSubmitted
 * @property {CompaniesItem3[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {CountriesItem1[] | null} countries - The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {DeliveryItem1[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {EmailItem1[] | null} email - The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {LogisticItem1[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {PermissionsItem4[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CompaniesItem4
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef PermissionsItem5
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem3
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef AllAccountSelection
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem4[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {PermissionsItem5[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {SupportedCountryItem3[] | null} supported_country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef AllUserDeselection
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {number | null} associated_users - The number of associated users. Sample: '1, 3, 5'
 * @property {boolean | null} change_settings_permission - The button 'change settings' is enable (true or false)
 * @property {boolean | null} invite_user_permission - The button 'add' is enable (true or false)
 * @property {string | null} purchase_experience - The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ApplicationsItem
 * @property {string | null} app_filter - The value chosen to filter the list. Which variable the user is using to perform the filtering action. Permitted values: All, admin portal, cmsadmin, deliveradmin, force, oncustomer, social, supplier
 */
/**
 * @typedef ApplicationFiltered
 * @property {ApplicationsItem[] | null} applications - The array of applications. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} filter_criteria - The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country', 'Application'
 * @property {string | null} list_type - The name of the screen where the user is taking this action. Permitted Values: USERS
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ApplicationSelected
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {number | null} applications_available - The number of apps available to be selected. Sample: '1, 3, 5'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef BulkActionsViewed
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ChangePurchaseExperienceStarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ChartNavigation
 * @property {boolean | null} manager_redirection - The manager redirection filed. Sample: True or False
 * @property {boolean | null} reporter_redirection - The reporter direction field Sample: True or False.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CopyCustomerRegistrationLink
 * @property {string | null} account_country - The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {string | null} new_user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CopyUserRegistrationLink
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_app - The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CountryFiltered
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_type - The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
 */
/**
 * @typedef CustomerUserInvitationCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CustomerUserInvitationFinished
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CustomerUserInvitationRestarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CustomerUserInvitationResult
 * @property {string | null} account_country - The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {string | null} new_user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {boolean | null} user_associated - The user was associated or not. It's a boolean. If trigarred, set True. If not, set False.
 * @property {boolean | null} user_invited - The user was invited or not. It's a boolean. If triggarred, set True. If not, set False.
 */
/**
 * @typedef CustomerUserInvitationSubmitted
 * @property {string | null} account_country - The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {string | null} new_user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef DescriptionEdited
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} entity_name - The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} entity_type - The value chosen to search.  Samples: 'Group','Role', 'Scope'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef EntitySearched
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} entity_name - The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} entity_type - The value chosen to search.  Samples: 'Group','Role', 'Scope'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ExportAccountList
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string} wholesaler_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} wholesaler_filter - The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'
 */
/**
 * @typedef ExportReportDownloaded
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string} wholesaler_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} wholesaler_filter - The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'
 */
/**
 * @typedef FileDownloaded
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef FileUploaded
 * @property {boolean | null} browse_button - Selecting the file by clicking on 'Browse File' or 'Droping it". It's a boolean. If triggared, set True. If not, set False
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ManagePermissionCanceled
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ManagePermissionStarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AdminPermissionItem
 * @property {string | null} admin_permissions - The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
 */
/**
 * @typedef ManagerPermissionSubmitted
 * @property {string | null} admin_affiliation - The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {AdminPermissionItem[] | null} admin_permission - The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_affiliation - The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {string | null} user_app - The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef NewUploadCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef NewUploadStarted
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef NewUploadSubmissionResult
 * @property {string | null} creation_date - The date of the upload. Sample: January 13, 2023 9:34 AM
 * @property {string | null} description - The description of the file uploaded. Sample: "Add permission for the user"
 * @property {string | null} file_name - The name of the file uploaded. Sample: "e9429287-86d9-417e-b700-6de0ac6a0199-1673298943073.csv"
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} status_upload - The status in the given context. Permitted value: Failed, Success, Partial Success
 * @property {string | null} title_name - The title of the file uploaded. Sample: "permission_manager.csv"
 * @property {string | null} url_download - The URL to dowload the file processed. Sample: "https://beesdevfilemgmt.blob.core.windows.net/files-br/identity-management-update-users_output-e9429287-86d9-417e-b700-6de0ac6a0199-1673298943073.csv?sig=jx%2FmYU8MPHJIiSOgm44K5xkU%2BfHJsczYlkHtTkroc%2FY%3D&se=2023-01-17T14%3A48%3A18Z&sv=2015-04-05&sp=r&sr=b"
 * @property {string | null} vendor_id - The vendor ID selected to manage customer users. Sample: 20210111-100902-1841701
 */
/**
 * @typedef NewUploadSubmitted
 * @property {string | null} description - The description of the file uploaded. Sample: "Add permission for the user"
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {number | null} rows_number - count CSV rows with data
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} title_name - The title of the file uploaded. Sample: "permission_manager.csv"
 * @property {string | null} vendor_id - The vendor ID selected to manage customer users. Sample: 20210111-100902-1841701
 */
/**
 * @typedef PageSelected
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {number | null} page_number - The number of the page in the given context. Samples: 1, 3, 5
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef PageViewed
 * @property {string | null} [action_type] - Action type is a property to store actions users perform on the events. Eg. Open, Close, Success, Dismiss, Cloned, etc.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef PermissionsViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef PurchaseExperienceSubmitted
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {number | null} associated_users - The number of associated users. Sample: '1, 3, 5'
 * @property {string | null} purchase_experience - The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RecentActivitiesSelected
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} tab_name - The value chosen to filter the list. Which variable the user is using to perform the tab selection. example: "completed", "pending"
 */
/**
 * @typedef RecentActivitiesViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RefreshedPage
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RemovedUserIdsItem
 * @property {string | null} removed_user_id - The removed user ids. Sample. 6096c481; 9238748932; 309128932
 */
/**
 * @typedef RemoveAdminAccess
 * @property {string | null} account_country - The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {RemovedUserIdsItem[] | null} removed_user_ids - The array of removed user ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RemoveScopeFromRole
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} role_name - The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RemoveScopeFromRoleCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ScopeNamesItem1
 * @property {string | null} scope_name - The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
 */
/**
 * @typedef RemoveScopeFromRoleSubmissionResult
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {number | null} remove_scopes_fail - The number of fails scope addition. Sample: '1, 3, 5'
 * @property {number | null} remove_scopes_success - The number of added scopes. Sample: '1, 3, 5'
 * @property {string | null} role_name - The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {ScopeNamesItem1[] | null} scope_names - The array of scope names. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RemoveScopeFromRoleSubmitted
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} role_name - The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} scopes_removed - The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ReporterDetailsRedirection
 * @property {string | null} reporter_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} reporter_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ReporterEmailCopy
 * @property {string | null} reporter_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} reporter_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ResendCustomerInvitation
 * @property {string | null} account_country - The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {string | null} new_user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ResendUserInvitation
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_app - The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef RoleCreationCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RoleCreationResult
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {boolean | null} role_creation - The role was created or not. It's a boolean. If trigarred, set True. If not, set False.
 * @property {string | null} role_name - The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RoleCreationStarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RoleCreationSubmitted
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} role_name - The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RoleDeletion
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} scope_name - The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ScopeCreationCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ScopeCreationResult
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {boolean | null} scope_creation - The scope was created or not. It's a boolean. If trigarred, set True. If not, set False.
 * @property {string | null} scope_name - The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ScopeCreationStarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ScopeCreationSubmitted
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} scope_name - The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef ScopeDeletion
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} scope_name - The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef TabSelected
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef TemplateDownloadedHome
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef TemplateDownloadedModal
 * @property {string | null} file_type - The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} vendor_id - The vendor ID selected to manage customer users. Sample: 20210111-100902-1841701
 */
/**
 * @typedef UserBlockCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AdminPermissionItem1
 * @property {string | null} admin_permissions - The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
 */
/**
 * @typedef UserBlocked
 * @property {string | null} admin_affiliation - The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {AdminPermissionItem1[] | null} admin_permission - The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_affiliation - The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {string | null} user_app - The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserChartViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserClicked
 * @property {number | null} clicked_item_position - The number of pages loaded. Samples: 10, 15, 30
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AdminPermissionItem2
 * @property {string | null} admin_permissions - The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
 */
/**
 * @typedef UserDetailsViewed
 * @property {string | null} admin_affiliation - The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {AdminPermissionItem2[] | null} admin_permission - The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_affiliation - The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {string | null} user_app - The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserEditingCanceled
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserEditingStarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CompaniesItem5
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef DdcIdsItem
 * @property {string | null} ddc_id - The DDC id generated. Sample: BK32
 */
/**
 * @typedef DeliveryItem2
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef LogisticItem2
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef PermissionsItem6
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem4
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef UserEditingSubmissionResult
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem5[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} company_association_fail_count - The number of fails company associantion. Sample: 1,2,3,4
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {number | null} country_association_fail_count - The number of fails supported country association. Sample: 1,2,3,4
 * @property {DdcIdsItem[] | null} ddc_ids - The array of ddcs Ids. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {DeliveryItem2[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} group_association_fail_count - The number of fails group associations. Sample: 1,2,3,4
 * @property {LogisticItem2[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {PermissionsItem6[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {SupportedCountryItem4[] | null} supported_country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CompaniesItem6
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef DeliveryItem3
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef LogisticItem3
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef PermissionsItem7
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef SupportedCountryItem5
 * @property {string | null} supported_countries - The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 */
/**
 * @typedef UserEditingSubmitted
 * @property {boolean | null} account_remove_permission - The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False
 * @property {number | null} associated_accounts - The number of associated accounts. Sample: '1, 3, 5'
 * @property {boolean | null} block_permission - The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {CompaniesItem6[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {DeliveryItem3[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {boolean | null} edit_permission - The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False
 * @property {LogisticItem3[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {PermissionsItem7[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {SupportedCountryItem5[] | null} supported_country - The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserInfoCopied
 * @property {string | null} copied_criteria - 'Copied value in the given context. Sample: 'ID' , 'phone', 'email'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserInvitationCanceled
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserInvitationCategorySelected
 * @property {string} invitation_category - The invitation category selected. Permitted Values.ONE or FORCE
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserInvitationFinished
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserInvitationRestarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserInvitationResult
 * @property {number | null} association_fail_count - The number of fails associations. Sample: 1,2,3,4
 * @property {number | null} association_success_count - The number of success associations. Sample: 1,2,3,4
 * @property {string} invitation_category - The invitation category selected. Permitted Values.ONE or FORCE
 * @property {number | null} invitation_fail_count - The number of fails invitations. Sample: 1,2,3,4
 * @property {number | null} invitation_success_count - The number of success invitations. Sample: 1,2,3,4
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserInvitationStarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef CompaniesItem7
 * @property {string | null} company_ids - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef CountriesItem2
 * @property {string | null} supported_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 */
/**
 * @typedef DeliveryItem4
 * @property {string | null} delivery_center_id - The delivery center id generated. Sample: BK32
 */
/**
 * @typedef LogisticItem4
 * @property {string | null} logistic_operator_id - The Logistic operator ID. Sample: 460751
 */
/**
 * @typedef NewUserInfoItem
 * @property {string | null} manager_email - The manager email in a given context. Permitted values. Samples. isabela.nogueira@ab-inbev.com
 * @property {string | null} supervisors_emails - The list of supervisors emails in a given context. Permitted values. Samples. isabela.nogueira@ab-inbev.com, isabela@gmail.com
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples. isabela.nogueira@ab-inbev.com
 */
/**
 * @typedef PermissionsItem8
 * @property {string | null} permissions_groups - Permissions groups of the user in the given context. Samples: 'Admin Invitation General'
 */
/**
 * @typedef UserInvitationSubmitted
 * @property {CompaniesItem7[] | null} companies - The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {CountriesItem2[] | null} countries - The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {DeliveryItem4[] | null} delivery - The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string} invitation_category - The invitation category selected. Permitted Values.ONE or FORCE
 * @property {LogisticItem4[] | null} logistic - The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {NewUserInfoItem[] | null} [new_user_info] - The array of new user information as email, manager and supervisor
 * @property {PermissionsItem8[] | null} permissions - The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} route_id - The Route ID used for force users. Sample: 20210111-100902-1841701
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserInvitationViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserListActions
 * @property {string | null} action_selected - The action in the given context. Permitted Values: User Details, Remove User
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} group_name - The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_selected - The user email in a given context. Permitted values. Samples: 'isabela@gmail.com'
 */
/**
 * @typedef UserListFiltered
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} country_filtered - The country in the given context. Permitted values: All,Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} group_name - The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef InformationItem
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked
 */
/**
 * @typedef UserListPagination
 * @property {number | null} clicked_item_position - The number of pages loaded. Samples: 10, 15, 30
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {InformationItem[] | null} information - The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
 */
/**
 * @typedef UserListSearched
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} group_name - The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
 */
/**
 * @typedef InformationItem1
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked
 */
/**
 * @typedef UserListViewed
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {InformationItem1[] | null} information - The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {number | null} listed_items - The number of items listed in the given context. Samples: 3, 8, 15
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
 * @property {string | null} user_type - The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
 */
/**
 * @typedef UserRemovalStarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef RemovedUserIdsItem1
 * @property {string | null} removed_user_id - The removed user ids. Sample. 6096c481; 9238748932; 309128932
 */
/**
 * @typedef UserRemovalSubmitted
 * @property {string | null} account_id - The account ID in a given context. Permitted values. Samples: 'H8999'
 * @property {string | null} account_name - The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'
 * @property {RemovedUserIdsItem1[] | null} removed_user_ids - The array of removed user ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserSearchCriteria
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string} search_criteria - The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', "phone', 'email', 'other'
 * @property {string | null} user_type - The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
 */
/**
 * @typedef UserTypeSelected
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_type - The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'
 */
/**
 * @typedef UserUnblockCanceled
 * @property {boolean | null} cancel_button - Cancel by clicking on 'X' or 'Cancel". It's a boolean. If triggared, set True. If not, set False
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AdminPermissionItem3
 * @property {string | null} admin_permissions - The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
 */
/**
 * @typedef UserUnblocked
 * @property {string | null} admin_affiliation - The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {AdminPermissionItem3[] | null} admin_permission - The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_affiliation - The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {string | null} user_app - The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
 * @property {string | null} user_email - The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 * @property {string | null} user_phone - The user phone number in the given context. Samples: +5519999841766
 * @property {string | null} user_status - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef UserViewed
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserExtractionStarted
 * @property {string | null} application_selected - The value chosen to start the experience. Samples: 'PIM' , 'Identity'
 * @property {string | null} group_name - The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserInformationEditCanceled
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef UserInformationEditStarted
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 */
/**
 * @typedef AdminPermissionItem4
 * @property {string | null} admin_permissions - The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst
 */
/**
 * @typedef UserInformationEditSubmitted
 * @property {string | null} admin_affiliation - The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {AdminPermissionItem4[] | null} admin_permission - The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI
 * @property {string | null} country - To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string | null} user_affiliation - The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer
 * @property {string | null} user_app - The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin
 * @property {string | null} user_id - The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e
 */
/**
 * @typedef WholesalerFiltered
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT
 * @property {string} wholesaler_country - The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay
 * @property {string | null} wholesaler_filter - The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'
 */

/**
 * Listing position the account was in when clicked
 *
 * @param {AccountClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountClicked(
	props: AccountClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Listing position the account was in when clicked',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					page_number: {
						description:
							'The number of the page in the given context. Samples: 1, 3, 5',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['listed_items', 'page_number', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Clicked',
		type: 'object',
	}
	const message = {
		event: 'Account Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user deselects an account
 *
 * @param {AccountDeselection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountDeselection(
	props: AccountDeselection,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user deselects an account',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_remove_permission: {
						description:
							"The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					associated_accounts: {
						description: "The number of associated accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					block_permission: {
						description:
							"The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					companies: {
						description:
							"The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								company_ids: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: ['company_ids'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					edit_permission: {
						description:
							"The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					permissions: {
						description:
							"The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								permissions_groups: {
									description:
										"Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
									type: ['string', 'null'],
								},
							},
							required: ['permissions_groups'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					supported_country: {
						description:
							"The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								supported_countries: {
									description:
										'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
									type: ['string', 'null'],
								},
							},
							required: ['supported_countries'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'account_remove_permission',
					'associated_accounts',
					'block_permission',
					'companies',
					'country',
					'edit_permission',
					'permissions',
					'screen_name',
					'supported_country',
					'user_email',
					'user_id',
					'user_phone',
					'user_status',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Deselection',
		type: 'object',
	}
	const message = {
		event: 'Account Deselection',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Deselection',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the Details tab of the Account Details page loads
 *
 * @param {AccountDetailsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountDetailsViewed(
	props: AccountDetailsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the Details tab of the Account Details page loads',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					associated_users: {
						description: "The number of associated users. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					change_settings_permission: {
						description:
							"The button 'change settings' is enable (true or false) ",
						type: ['boolean', 'null'],
					},
					invite_user_permission: {
						description: "The button 'add' is enable (true or false) ",
						type: ['boolean', 'null'],
					},
					purchase_experience: {
						description:
							'The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer',
						enum: [
							'BEES_CUSTOMER',
							'BEES_LINK',
							'REGULAR',
							'SMALL_FORMAT',
							'KEY_ACCOUNT',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_id',
					'account_name',
					'associated_users',
					'change_settings_permission',
					'invite_user_permission',
					'purchase_experience',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Details Viewed',
		type: 'object',
	}
	const message = {
		event: 'Account Details Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigger when the user execute a new file
 *
 * @param {AccountLinkingExecutionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountLinkingExecutionStarted(
	props: AccountLinkingExecutionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Trigger when the user execute a new file',
		labels: {
			vs: 'identity_admin',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					file_country: {
						description: 'country of the uploaded file',
						type: ['string', 'null'],
					},
					file_id: {
						description:
							'The file Id generated in the service. Sample. 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e',
						type: ['string', 'null'],
					},
					file_status: {
						description: 'status of the uploaded file',
						type: ['string', 'null'],
					},
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'file_status',
					'file_type',
					'file_id',
					'file_country',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Linking Execution Started',
		type: 'object',
	}
	const message = {
		event: 'Account Linking Execution Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Linking Execution Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Trigger when the user validate a new file
 *
 * @param {AccountLinkingValidationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountLinkingValidationStarted(
	props: AccountLinkingValidationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Trigger when the user validate a new file',
		labels: {
			vs: 'identity_admin',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					file_country: {
						description: 'country of the uploaded file',
						type: ['string', 'null'],
					},
					file_id: {
						description:
							'The file Id generated in the service. Sample. 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e',
						type: ['string', 'null'],
					},
					file_status: {
						description: 'status of the uploaded file',
						type: ['string', 'null'],
					},
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'file_status',
					'file_type',
					'file_id',
					'file_country',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Linking Validation Started',
		type: 'object',
	}
	const message = {
		event: 'Account Linking Validation Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Linking Validation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects terms to search a list based on a given criteria
 *
 * @param {AccountListSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountListSearched(
	props: AccountListSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects terms to search a list based on a given criteria',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					purchase_experience: {
						description:
							'The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer',
						enum: [
							'BEES_CUSTOMER',
							'BEES_LINK',
							'REGULAR',
							'SMALL_FORMAT',
							'KEY_ACCOUNT',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					search_criteria: {
						description:
							"The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', \"phone', 'email', 'other'",
						enum: ['EMAIL', 'ID', 'NAME', 'OTHER', 'PHONE'],
						type: ['string'],
					},
				},
				required: ['purchase_experience', 'screen_name', 'search_criteria'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account List Searched',
		type: 'object',
	}
	const message = {
		event: 'Account List Searched',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account List Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user accesses the Account Tab and sees the list of accounts
 *
 * @param {AccountListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountListViewed(
	props: AccountListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user accesses the Account Tab and sees the list of accounts ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_information: {
						description:
							"The array of account information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								account_id: {
									description:
										"The account ID in a given context. Permitted values. Samples: 'H8999'",
									type: ['string', 'null'],
								},
								account_name: {
									description:
										"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
									type: ['string', 'null'],
								},
								purchase_experience: {
									description:
										'The user purchase experience in the given context. Samples: Bees Link, Bees Customer',
									type: ['string', 'null'],
								},
								user_associated: {
									description:
										"The number of associated users. Sample: '1, 3, 5'",
									type: ['number', 'null'],
								},
							},
							required: [
								'account_id',
								'account_name',
								'purchase_experience',
								'user_associated',
							],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					search_criteria: {
						description:
							"The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', \"phone', 'email', 'other'",
						enum: ['EMAIL', 'ID', 'NAME', 'OTHER', 'PHONE'],
						type: ['string'],
					},
					wholesaler_filter: {
						description:
							"The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'",
						enum: ['BRANCH', 'WHOLESALER', null],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_information',
					'listed_items',
					'screen_name',
					'search_criteria',
					'wholesaler_filter',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account List Viewed',
		type: 'object',
	}
	const message = {
		event: 'Account List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user cancels the User Removal journey by clicking on the "Cancel" button on the Confirmation Modal 
 *
 * @param {AccountRemovalCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountRemovalCanceled(
	props: AccountRemovalCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user cancels the User Removal journey by clicking on the "Cancel" button on the Confirmation Modal ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Removal Canceled',
		type: 'object',
	}
	const message = {
		event: 'Account Removal Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Removal Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user starts the Account Removal journey by clicking on the "Remove" button on the User Details Tab
 *
 * @param {AccountRemovalStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountRemovalStarted(
	props: AccountRemovalStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user starts the Account Removal journey by clicking on the "Remove" button on the User Details Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					associated_accounts: {
						description: "The number of associated accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					selected_accounts: {
						description: "The number of selected accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'associated_accounts',
					'screen_name',
					'selected_accounts',
					'user_id',
					'country',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Removal Started',
		type: 'object',
	}
	const message = {
		event: 'Account Removal Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Removal Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the process of the User Removal is finished and returns feedback (failure and/or success)
 *
 * @param {AccountRemovalSubmissionResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountRemovalSubmissionResult(
	props: AccountRemovalSubmissionResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the process of the User Removal is finished and returns feedback (failure and/or success)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_remove_permission: {
						description:
							"The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					associated_accounts: {
						description: "The number of associated accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					block_permission: {
						description:
							"The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					companies: {
						description:
							"The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								company_ids: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: ['company_ids'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					edit_permission: {
						description:
							"The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					permissions: {
						description:
							"The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								permissions_groups: {
									description:
										"Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
									type: ['string', 'null'],
								},
							},
							required: ['permissions_groups'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					removal_fail_count: {
						description: 'The number of fail removal. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					selected_accounts: {
						description: "The number of selected accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					supported_country: {
						description:
							"The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								supported_countries: {
									description:
										'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
									type: ['string', 'null'],
								},
							},
							required: ['supported_countries'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'account_remove_permission',
					'associated_accounts',
					'block_permission',
					'companies',
					'country',
					'edit_permission',
					'permissions',
					'removal_fail_count',
					'screen_name',
					'selected_accounts',
					'supported_country',
					'user_email',
					'user_id',
					'user_phone',
					'user_status',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Removal Submission Result',
		type: 'object',
	}
	const message = {
		event: 'Account Removal Submission Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Removal Submission Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "Remove" button on the Confirmation Modal
 *
 * @param {AccountRemovalSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountRemovalSubmitted(
	props: AccountRemovalSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "Remove" button on the Confirmation Modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					associated_accounts: {
						description: "The number of associated accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					selected_accounts: {
						description: "The number of selected accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					user_app: {
						description:
							'The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'associated_accounts',
					'country',
					'screen_name',
					'selected_accounts',
					'user_id',
					'user_app',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Removal Submitted',
		type: 'object',
	}
	const message = {
		event: 'Account Removal Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Removal Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user selects accounts
 *
 * @param {AccountSelection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountSelection(
	props: AccountSelection,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user selects accounts',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_remove_permission: {
						description:
							"The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					associated_accounts: {
						description: "The number of associated accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					block_permission: {
						description:
							"The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					companies: {
						description:
							"The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								company_ids: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: ['company_ids'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					edit_permission: {
						description:
							"The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					permissions: {
						description:
							"The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								permissions_groups: {
									description:
										"Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
									type: ['string', 'null'],
								},
							},
							required: ['permissions_groups'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					supported_country: {
						description:
							"The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								supported_countries: {
									description:
										'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
									type: ['string', 'null'],
								},
							},
							required: ['supported_countries'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'account_remove_permission',
					'associated_accounts',
					'block_permission',
					'companies',
					'country',
					'edit_permission',
					'permissions',
					'screen_name',
					'supported_country',
					'user_email',
					'user_id',
					'user_phone',
					'user_status',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Account Selection',
		type: 'object',
	}
	const message = {
		event: 'Account Selection',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Selection',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to add the Admin access
 *
 * @param {AddAdminAccess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addAdminAccess(
	props: AddAdminAccess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to add the Admin access',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_country: {
						description:
							'The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						type: ['string', 'null'],
					},
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					added_user_ids: {
						description:
							"The array of added user ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								added_user_id: {
									description:
										'The added user ids. Sample. 6096c481; 9238748932; 309128932',
									type: ['string', 'null'],
								},
							},
							required: ['added_user_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_country',
					'account_id',
					'account_name',
					'added_user_ids',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Add Admin Access',
		type: 'object',
	}
	const message = {
		event: 'Add Admin Access',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add Admin Access',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the User Invitation journey by clicking on the "Add " button on the Account Details Tab
 *
 * @param {AddNewUserStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addNewUserStarted(
	props: AddNewUserStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the User Invitation journey by clicking on the "Add " button on the Account Details Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Add New User Started',
		type: 'object',
	}
	const message = {
		event: 'Add New User Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add New User Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to "add scope" in the role details page


 *
 * @param {AddScopeToRole} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addScopeToRole(
	props: AddScopeToRole,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to "add scope" in the role details page\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					role_name: {
						description:
							"The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['application_selected', 'role_name', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Add Scope To Role',
		type: 'object',
	}
	const message = {
		event: 'Add Scope To Role',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add Scope To Role',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to cancel the add scope to role

 *
 * @param {AddScopeToRoleCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addScopeToRoleCanceled(
	props: AddScopeToRoleCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user click to cancel the add scope to role\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					cancel_button: {
						description:
							"Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['cancel_button', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Add Scope To Role Canceled',
		type: 'object',
	}
	const message = {
		event: 'Add Scope To Role Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add Scope To Role Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the Add Scope to Role is finished and returns feedback (failure and/or success)





 *
 * @param {AddScopeToRoleSubmissionResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addScopeToRoleSubmissionResult(
	props: AddScopeToRoleSubmissionResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the Add Scope to Role is finished and returns feedback (failure and/or success)\n\n\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					add_scopes_fail: {
						description:
							"The number of fails scope addition. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					add_scopes_success: {
						description: "The number of added scopes. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					role_name: {
						description:
							"The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					scope_names: {
						description:
							"The array of scope names. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								scope_name: {
									description:
										"The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'",
									type: ['string', 'null'],
								},
							},
							required: ['scope_name'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'add_scopes_fail',
					'add_scopes_success',
					'application_selected',
					'role_name',
					'scope_names',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Add Scope To Role Submission Result',
		type: 'object',
	}
	const message = {
		event: 'Add Scope To Role Submission Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add Scope To Role Submission Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to "add" the scopes to the role in the modal



 *
 * @param {AddScopeToRoleSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addScopeToRoleSubmitted(
	props: AddScopeToRoleSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user click to "add" the scopes to the role in the modal\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					role_name: {
						description:
							"The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					scopes_added: {
						description:
							"The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'application_selected',
					'role_name',
					'scopes_added',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Add Scope To Role Submitted',
		type: 'object',
	}
	const message = {
		event: 'Add Scope To Role Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Add Scope To Role Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects the "Administrator" option
 *
 * @param {AdminUserCategorySelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserCategorySelected(
	props: AdminUserCategorySelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user selects the "Administrator" option',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Admin User Category Selected',
		type: 'object',
	}
	const message = {
		event: 'Admin User Category Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Admin User Category Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "back" button
 *
 * @param {AdminUserInvitationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationCanceled(
	props: AdminUserInvitationCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on the "back" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Admin User Invitation Canceled',
		type: 'object',
	}
	const message = {
		event: 'Admin User Invitation Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Admin User Invitation Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "Done" button
 *
 * @param {AdminUserInvitationFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationFinished(
	props: AdminUserInvitationFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on the "Done" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Admin User Invitation Finished',
		type: 'object',
	}
	const message = {
		event: 'Admin User Invitation Finished',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Admin User Invitation Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "Add new user" button
 *
 * @param {AdminUserInvitationRestarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationRestarted(
	props: AdminUserInvitationRestarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on the "Add new user" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Admin User Invitation Restarted',
		type: 'object',
	}
	const message = {
		event: 'Admin User Invitation Restarted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Admin User Invitation Restarted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the Admin User Invitation is finished and returns feedback (failure and/or success)
 *
 * @param {AdminUserInvitationResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationResult(
	props: AdminUserInvitationResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the Admin User Invitation is finished and returns feedback (failure and/or success)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					association_fail_count: {
						description: 'The number of fails associations. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					association_success_count: {
						description: 'The number of success associations. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					countries: {
						description:
							"The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								supported_country: {
									description:
										'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
									enum: [
										'AQ',
										'AR',
										'BB',
										'BE',
										'BO',
										'BR',
										'CA',
										'CL',
										'CO',
										'CZ',
										'DE',
										'DO',
										'ES',
										'EC',
										'GB',
										'HN',
										'IN',
										'ID',
										'KR',
										'MX',
										'NL',
										'NG',
										'PA',
										'PY',
										'PE',
										'PH',
										'TH',
										'TZ',
										'UG',
										'US',
										'UY',
										'ZA',
										null,
									],
									type: ['string', 'null'],
								},
							},
							required: ['supported_country'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					delivery: {
						description:
							"The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								delivery_center_id: {
									description: 'The delivery center id generated. Sample: BK32',
									type: ['string', 'null'],
								},
							},
							required: ['delivery_center_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					email: {
						description:
							"The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								email_list: {
									description:
										'The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
									type: ['string', 'null'],
								},
							},
							required: ['email_list'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					invitation_fail_count: {
						description: 'The number of fails invitations. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					invitation_success_count: {
						description: 'The number of success invitations. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					logistic: {
						description:
							"The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								logistic_operator_id: {
									description: 'The Logistic operator ID. Sample: 460751',
									type: ['string', 'null'],
								},
							},
							required: ['logistic_operator_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					permissions: {
						description:
							"The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								permissions_groups: {
									description:
										"Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
									type: ['string', 'null'],
								},
							},
							required: ['permissions_groups'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					route_id: {
						description:
							'The Route ID used for force users. Sample: 20210111-100902-1841701',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'association_fail_count',
					'association_success_count',
					'countries',
					'country',
					'delivery',
					'email',
					'invitation_fail_count',
					'invitation_success_count',
					'logistic',
					'permissions',
					'route_id',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Admin User Invitation Result',
		type: 'object',
	}
	const message = {
		event: 'Admin User Invitation Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Admin User Invitation Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "finish" button
 *
 * @param {AdminUserInvitationSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function adminUserInvitationSubmitted(
	props: AdminUserInvitationSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on the "finish" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					companies: {
						description:
							"The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								company_ids: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: ['company_ids'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					countries: {
						description:
							"The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								supported_country: {
									description:
										'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
									enum: [
										'AQ',
										'AR',
										'BB',
										'BE',
										'BO',
										'BR',
										'CA',
										'CL',
										'CO',
										'CZ',
										'DE',
										'DO',
										'ES',
										'EC',
										'GB',
										'HN',
										'IN',
										'ID',
										'KR',
										'MX',
										'NL',
										'NG',
										'PA',
										'PY',
										'PE',
										'PH',
										'TH',
										'TZ',
										'UG',
										'US',
										'UY',
										'ZA',
										null,
									],
									type: ['string', 'null'],
								},
							},
							required: ['supported_country'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					delivery: {
						description:
							"The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								delivery_center_id: {
									description: 'The delivery center id generated. Sample: BK32',
									type: ['string', 'null'],
								},
							},
							required: ['delivery_center_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					email: {
						description:
							"The array of emails. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								email_list: {
									description:
										'The list of invited emails in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
									type: ['string', 'null'],
								},
							},
							required: ['email_list'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					logistic: {
						description:
							"The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								logistic_operator_id: {
									description: 'The Logistic operator ID. Sample: 460751',
									type: ['string', 'null'],
								},
							},
							required: ['logistic_operator_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					permissions: {
						description:
							"The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								permissions_groups: {
									description:
										"Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
									type: ['string', 'null'],
								},
							},
							required: ['permissions_groups'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					route_id: {
						description:
							'The Route ID used for force users. Sample: 20210111-100902-1841701',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'companies',
					'countries',
					'country',
					'delivery',
					'email',
					'logistic',
					'permissions',
					'route_id',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Admin User Invitation Submitted',
		type: 'object',
	}
	const message = {
		event: 'Admin User Invitation Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Admin User Invitation Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user clicks on 'Select All'
 *
 * @param {AllAccountSelection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function allAccountSelection(
	props: AllAccountSelection,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: "Triggered when user clicks on 'Select All'",
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_remove_permission: {
						description:
							"The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					associated_accounts: {
						description: "The number of associated accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					block_permission: {
						description:
							"The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					companies: {
						description:
							"The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								company_ids: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: ['company_ids'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					edit_permission: {
						description:
							"The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					permissions: {
						description:
							"The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								permissions_groups: {
									description:
										"Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
									type: ['string', 'null'],
								},
							},
							required: ['permissions_groups'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					supported_country: {
						description:
							"The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								supported_countries: {
									description:
										'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
									type: ['string', 'null'],
								},
							},
							required: ['supported_countries'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'account_remove_permission',
					'associated_accounts',
					'block_permission',
					'companies',
					'country',
					'edit_permission',
					'permissions',
					'screen_name',
					'supported_country',
					'user_email',
					'user_id',
					'user_phone',
					'user_status',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'All Account Selection',
		type: 'object',
	}
	const message = {
		event: 'All Account Selection',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'All Account Selection',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user clicks on 'Deselect All'
 *
 * @param {AllUserDeselection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function allUserDeselection(
	props: AllUserDeselection,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: "Triggered when user clicks on 'Deselect All'",
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					associated_users: {
						description: "The number of associated users. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					change_settings_permission: {
						description:
							"The button 'change settings' is enable (true or false) ",
						type: ['boolean', 'null'],
					},
					invite_user_permission: {
						description: "The button 'add' is enable (true or false) ",
						type: ['boolean', 'null'],
					},
					purchase_experience: {
						description:
							'The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer',
						enum: [
							'BEES_CUSTOMER',
							'BEES_LINK',
							'REGULAR',
							'SMALL_FORMAT',
							'KEY_ACCOUNT',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_id',
					'account_name',
					'associated_users',
					'change_settings_permission',
					'invite_user_permission',
					'purchase_experience',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'All User Deselection',
		type: 'object',
	}
	const message = {
		event: 'All User Deselection',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'All User Deselection',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user filters the list by application
 *
 * @param {ApplicationFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationFiltered(
	props: ApplicationFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user filters the list by application',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					applications: {
						description:
							"The array of applications. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								app_filter: {
									description:
										'The value chosen to filter the list. Which variable the user is using to perform the filtering action. Permitted values: All, admin portal, cmsadmin, deliveradmin, force, oncustomer, social, supplier',
									enum: [
										'ADMIN_PORTAL',
										'ALL',
										'CMSADMIN',
										'DELIVERADMIN',
										'FORCE',
										'ONCUSTOMER',
										'SOCIAL',
										'SUPPLIER',
										null,
									],
									type: ['string', 'null'],
								},
							},
							required: ['app_filter'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					filter_criteria: {
						description:
							"The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Country', 'Application'",
						enum: ['APPLICATION', 'COUNTRY', null],
						type: ['string', 'null'],
					},
					list_type: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values: USERS',
						enum: ['USERS', null],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'applications',
					'filter_criteria',
					'list_type',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Application Filtered',
		type: 'object',
	}
	const message = {
		event: 'Application Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Application Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects the application


 *
 * @param {ApplicationSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function applicationSelected(
	props: ApplicationSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user selects the application\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					applications_available: {
						description:
							"The number of apps available to be selected. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'application_selected',
					'applications_available',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Application Selected',
		type: 'object',
	}
	const message = {
		event: 'Application Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Application Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user accesses the Bulk Action Tab
 *
 * @param {BulkActionsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bulkActionsViewed(
	props: BulkActionsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user accesses the Bulk Action Tab ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['file_type', 'listed_items', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Bulk Actions Viewed',
		type: 'object',
	}
	const message = {
		event: 'Bulk Actions Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Bulk Actions Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the Change Settings journey by clicking on the "Change Purchase" button.
 *
 * @param {ChangePurchaseExperienceStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changePurchaseExperienceStarted(
	props: ChangePurchaseExperienceStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the Change Settings journey by clicking on the "Change Purchase" button.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Change Purchase Experience Started',
		type: 'object',
	}
	const message = {
		event: 'Change Purchase Experience Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Change Purchase Experience Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tiggered when the user clicks on the arrow or the reporter card
 *
 * @param {ChartNavigation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function chartNavigation(
	props: ChartNavigation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Tiggered when the user clicks on the arrow or the reporter card',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					manager_redirection: {
						description: 'The manager redirection filed. Sample: True or False',
						type: ['boolean', 'null'],
					},
					reporter_redirection: {
						description: 'The reporter direction field Sample: True or False.',
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'manager_redirection',
					'reporter_redirection',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Chart Navigation',
		type: 'object',
	}
	const message = {
		event: 'Chart Navigation',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Chart Navigation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to resend invitation
 *
 * @param {CopyCustomerRegistrationLink} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function copyCustomerRegistrationLink(
	props: CopyCustomerRegistrationLink,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to resend invitation',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_country: {
						description:
							'The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						type: ['string', 'null'],
					},
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					new_user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_country',
					'account_id',
					'account_name',
					'new_user_email',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Copy Customer Registration Link',
		type: 'object',
	}
	const message = {
		event: 'Copy Customer Registration Link',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Copy Customer Registration Link',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to resend invitation
 *
 * @param {CopyUserRegistrationLink} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function copyUserRegistrationLink(
	props: CopyUserRegistrationLink,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to resend invitation',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_app: {
						description:
							'The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: ['country', 'screen_name', 'user_app', 'user_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Copy User Registration Link',
		type: 'object',
	}
	const message = {
		event: 'Copy User Registration Link',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Copy User Registration Link',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user filters the list by Country
 *
 * @param {CountryFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function countryFiltered(
	props: CountryFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: '\nTriggered when the user filters the list by Country',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							"The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'",
						enum: ['CUSTOMER', 'ONE', 'FORCE', 'DTC', null],
						type: ['string', 'null'],
					},
				},
				required: ['country', 'user_type', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Country Filtered',
		type: 'object',
	}
	const message = {
		event: 'Country Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Country Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user cancels the User Invitation journey by clicking on the "Back" button on the User Invitation Modal
 *
 * @param {CustomerUserInvitationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationCanceled(
	props: CustomerUserInvitationCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user cancels the User Invitation journey by clicking on the "Back" button on the User Invitation Modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					cancel_button: {
						description:
							"Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['cancel_button', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Customer User Invitation Canceled',
		type: 'object',
	}
	const message = {
		event: 'Customer User Invitation Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Customer User Invitation Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tiggered when the user clicks on the "Done" button
 *
 * @param {CustomerUserInvitationFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationFinished(
	props: CustomerUserInvitationFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Tiggered when the user clicks on the "Done" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Customer User Invitation Finished',
		type: 'object',
	}
	const message = {
		event: 'Customer User Invitation Finished',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Customer User Invitation Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tiggered when the user clicks on the "Add new user" button
 *
 * @param {CustomerUserInvitationRestarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationRestarted(
	props: CustomerUserInvitationRestarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Tiggered when the user clicks on the "Add new user" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Customer User Invitation Restarted',
		type: 'object',
	}
	const message = {
		event: 'Customer User Invitation Restarted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Customer User Invitation Restarted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the User Invitation is failed.
 *
 * @param {CustomerUserInvitationResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationResult(
	props: CustomerUserInvitationResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the User Invitation is failed. ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_country: {
						description:
							'The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						type: ['string', 'null'],
					},
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					new_user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_associated: {
						description:
							"The user was associated or not. It's a boolean. If trigarred, set True. If not, set False.",
						type: ['boolean', 'null'],
					},
					user_invited: {
						description:
							"The user was invited or not. It's a boolean. If triggarred, set True. If not, set False.",
						type: ['boolean', 'null'],
					},
				},
				required: [
					'account_country',
					'account_id',
					'account_name',
					'new_user_email',
					'screen_name',
					'user_associated',
					'user_invited',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Customer User Invitation Result',
		type: 'object',
	}
	const message = {
		event: 'Customer User Invitation Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Customer User Invitation Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user finishes to fill-out the user invitation forms and clicks on the "Finish" button
 *
 * @param {CustomerUserInvitationSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customerUserInvitationSubmitted(
	props: CustomerUserInvitationSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user finishes to fill-out the user invitation forms and clicks on the "Finish" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_country: {
						description:
							'The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						type: ['string', 'null'],
					},
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					new_user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_country',
					'account_id',
					'account_name',
					'new_user_email',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Customer User Invitation Submitted',
		type: 'object',
	}
	const message = {
		event: 'Customer User Invitation Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Customer User Invitation Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to edit the description



 *
 * @param {DescriptionEdited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function descriptionEdited(
	props: DescriptionEdited,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to edit the description\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					entity_name: {
						description:
							"The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					entity_type: {
						description:
							"The value chosen to search.  Samples: 'Group','Role', 'Scope'",
						enum: ['GROUP', 'ROLE', 'SCOPE', null],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'application_selected',
					'entity_name',
					'entity_type',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Description Edited',
		type: 'object',
	}
	const message = {
		event: 'Description Edited',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Description Edited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user select a entity type and search 



 *
 * @param {EntitySearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function entitySearched(
	props: EntitySearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user select a entity type and search \n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					entity_name: {
						description:
							"The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					entity_type: {
						description:
							"The value chosen to search.  Samples: 'Group','Role', 'Scope'",
						enum: ['GROUP', 'ROLE', 'SCOPE', null],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'application_selected',
					'entity_name',
					'entity_type',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Entity Searched',
		type: 'object',
	}
	const message = {
		event: 'Entity Searched',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Entity Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user export the list of accounts.
 *
 * @param {ExportAccountList} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportAccountList(
	props: ExportAccountList,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user export the list of accounts.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					wholesaler_country: {
						description:
							'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						enum: [
							'AR',
							'BR',
							'CA',
							'CO',
							'DR',
							'EC',
							'HN',
							'KR',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UG',
							'UK',
							'US',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					wholesaler_filter: {
						description:
							"The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'",
						enum: ['BRANCH', 'WHOLESALER', null],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'wholesaler_country', 'wholesaler_filter'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Export Account List',
		type: 'object',
	}
	const message = {
		event: 'Export Account List',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Export Account List',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user dowload the export report.
 *
 * @param {ExportReportDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exportReportDownloaded(
	props: ExportReportDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user dowload the export report.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					wholesaler_country: {
						description:
							'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						enum: [
							'AR',
							'BR',
							'CA',
							'CO',
							'DR',
							'EC',
							'HN',
							'KR',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UG',
							'UK',
							'US',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					wholesaler_filter: {
						description:
							"The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'",
						enum: ['BRANCH', 'WHOLESALER', null],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'wholesaler_country', 'wholesaler_filter'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Export Report Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Export Report Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Export Report Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "download" icon on the home page
 *
 * @param {FileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileDownloaded(
	props: FileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "download" icon on the home page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['file_type', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'File Downloaded',
		type: 'object',
	}
	const message = {
		event: 'File Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user upload the file
 *
 * @param {FileUploaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function fileUploaded(
	props: FileUploaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user upload the file',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					browse_button: {
						description:
							"Selecting the file by clicking on 'Browse File' or 'Droping it\". It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['browse_button', 'file_type', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'File Uploaded',
		type: 'object',
	}
	const message = {
		event: 'File Uploaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'File Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user cancels the Manager permission journey by clicking on the "Cancel" button
 *
 * @param {ManagePermissionCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function managePermissionCanceled(
	props: ManagePermissionCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user cancels the Manager permission journey by clicking on the "Cancel" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Manage Permission Canceled',
		type: 'object',
	}
	const message = {
		event: 'Manage Permission Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Manage Permission Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the Manager permission journey by clicking on the "Manage permission" button on the User Details Tab
 *
 * @param {ManagePermissionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function managePermissionStarted(
	props: ManagePermissionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the Manager permission journey by clicking on the "Manage permission" button on the User Details Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Manage Permission Started',
		type: 'object',
	}
	const message = {
		event: 'Manage Permission Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Manage Permission Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user finishes to fill out the manage permission page and save changes
 *
 * @param {ManagerPermissionSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function managerPermissionSubmitted(
	props: ManagerPermissionSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user finishes to fill out the manage permission page and save changes',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					admin_affiliation: {
						description:
							'The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					admin_permission: {
						description:
							"The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								admin_permissions: {
									description:
										'The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst',
									type: ['string', 'null'],
								},
							},
							required: ['admin_permissions'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					user_app: {
						description:
							'The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'admin_permission',
					'admin_affiliation',
					'country',
					'user_email',
					'user_phone',
					'user_status',
					'user_id',
					'user_app',
					'user_affiliation',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Manager permission submitted',
		type: 'object',
	}
	const message = {
		event: 'Manager permission submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Manager permission submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user cancels the new upload
 *
 * @param {NewUploadCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newUploadCanceled(
	props: NewUploadCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user cancels the new upload',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					cancel_button: {
						description:
							"Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['cancel_button', 'file_type', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'New Upload Canceled',
		type: 'object',
	}
	const message = {
		event: 'New Upload Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'New Upload Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "upload csv file" button
 *
 * @param {NewUploadStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newUploadStarted(
	props: NewUploadStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "upload csv file" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['file_type', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'New Upload Started',
		type: 'object',
	}
	const message = {
		event: 'New Upload Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'New Upload Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the upload process is finished and returns feedback (failure, partial success or success)
 *
 * @param {NewUploadSubmissionResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newUploadSubmissionResult(
	props: NewUploadSubmissionResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the upload process is finished and returns feedback (failure, partial success or success)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					creation_date: {
						description:
							'The date of the upload. Sample: January 13, 2023 9:34 AM',
						type: ['string', 'null'],
					},
					description: {
						description:
							'The description of the file uploaded. Sample: "Add permission for the user"',
						type: ['string', 'null'],
					},
					file_name: {
						description:
							'The name of the file uploaded. Sample: "e9429287-86d9-417e-b700-6de0ac6a0199-1673298943073.csv"',
						type: ['string', 'null'],
					},
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					status_upload: {
						description:
							'The status in the given context. Permitted value: Failed, Success, Partial Success',
						type: ['string', 'null'],
					},
					title_name: {
						description:
							'The title of the file uploaded. Sample: "permission_manager.csv"',
						type: ['string', 'null'],
					},
					url_download: {
						description:
							'The URL to dowload the file processed. Sample: "https://beesdevfilemgmt.blob.core.windows.net/files-br/identity-management-update-users_output-e9429287-86d9-417e-b700-6de0ac6a0199-1673298943073.csv?sig=jx%2FmYU8MPHJIiSOgm44K5xkU%2BfHJsczYlkHtTkroc%2FY%3D&se=2023-01-17T14%3A48%3A18Z&sv=2015-04-05&sp=r&sr=b"',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							'The vendor ID selected to manage customer users. Sample: 20210111-100902-1841701',
						type: ['string', 'null'],
					},
				},
				required: [
					'creation_date',
					'description',
					'file_name',
					'file_type',
					'screen_name',
					'status_upload',
					'title_name',
					'url_download',
					'vendor_id',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'New Upload Submission Result',
		type: 'object',
	}
	const message = {
		event: 'New Upload Submission Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'New Upload Submission Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "upload" button in the modal
 *
 * @param {NewUploadSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newUploadSubmitted(
	props: NewUploadSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "upload" button in the modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					description: {
						description:
							'The description of the file uploaded. Sample: "Add permission for the user"',
						type: ['string', 'null'],
					},
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					rows_number: {
						description: 'count CSV rows with data',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					title_name: {
						description:
							'The title of the file uploaded. Sample: "permission_manager.csv"',
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							'The vendor ID selected to manage customer users. Sample: 20210111-100902-1841701',
						type: ['string', 'null'],
					},
				},
				required: [
					'description',
					'file_type',
					'rows_number',
					'screen_name',
					'title_name',
					'vendor_id',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'New Upload Submitted',
		type: 'object',
	}
	const message = {
		event: 'New Upload Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'New Upload Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on a  page number to view other a second page for example (1, 2, 3, 4, 5, 6, … , 10)
 *
 * @param {PageSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageSelected(
	props: PageSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on a  page number to view other a second page for example (1, 2, 3, 4, 5, 6, … , 10)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					page_number: {
						description:
							'The number of the page in the given context. Samples: 1, 3, 5',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['file_type', 'listed_items', 'page_number', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Page Selected',
		type: 'object',
	}
	const message = {
		event: 'Page Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on any page inside Identity Management FE
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'When the user clicks on any page inside Identity Management FE',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					action_type: {
						description:
							'Action type is a property to store actions users perform on the events. Eg. Open, Close, Success, Dismiss, Cloned, etc.',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Page Viewed',
		type: 'object',
	}
	const message = {
		event: 'Page Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user accesses the Roles and Permissions Tab
 *
 * @param {PermissionsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function permissionsViewed(
	props: PermissionsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user accesses the Roles and Permissions Tab ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Permissions Viewed',
		type: 'object',
	}
	const message = {
		event: 'Permissions Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Permissions Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user select the new purchase experience option and clicks on the "Save Changes" button on the Change Settings Modal.
 *
 * @param {PurchaseExperienceSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function purchaseExperienceSubmitted(
	props: PurchaseExperienceSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user select the new purchase experience option and clicks on the "Save Changes" button on the Change Settings Modal.',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					associated_users: {
						description: "The number of associated users. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					purchase_experience: {
						description:
							'The user purchase experience in the given context. Permitted Values: Bees Link, Bees Customer',
						enum: [
							'BEES_CUSTOMER',
							'BEES_LINK',
							'REGULAR',
							'SMALL_FORMAT',
							'KEY_ACCOUNT',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_id',
					'account_name',
					'associated_users',
					'purchase_experience',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Purchase Experience Submitted',
		type: 'object',
	}
	const message = {
		event: 'Purchase Experience Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Purchase Experience Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "pending" or the "completed" tab in the Recent Activities modal 

 *
 * @param {RecentActivitiesSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recentActivitiesSelected(
	props: RecentActivitiesSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "pending" or the "completed" tab in the Recent Activities modal \n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					tab_name: {
						description:
							'The value chosen to filter the list. Which variable the user is using to perform the tab selection. example: "completed", "pending"',
						enum: ['COMPLETED', 'PENDING', null],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'tab_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Recent Activities Selected',
		type: 'object',
	}
	const message = {
		event: 'Recent Activities Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Recent Activities Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the Recent Activities button in the User Details page
 *
 * @param {RecentActivitiesViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function recentActivitiesViewed(
	props: RecentActivitiesViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the Recent Activities button in the User Details page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Recent Activities Viewed',
		type: 'object',
	}
	const message = {
		event: 'Recent Activities Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Recent Activities Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the Refresh button
 *
 * @param {RefreshedPage} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function refreshedPage(
	props: RefreshedPage,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on the Refresh button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['file_type', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Refreshed Page',
		type: 'object',
	}
	const message = {
		event: 'Refreshed Page',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Refreshed Page',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to remove the Admin access
 *
 * @param {RemoveAdminAccess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removeAdminAccess(
	props: RemoveAdminAccess,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to remove the Admin access',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_country: {
						description:
							'The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						type: ['string', 'null'],
					},
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					removed_user_ids: {
						description:
							"The array of removed user ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								removed_user_id: {
									description:
										'The removed user ids. Sample. 6096c481; 9238748932; 309128932',
									type: ['string', 'null'],
								},
							},
							required: ['removed_user_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_country',
					'account_id',
					'account_name',
					'removed_user_ids',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Remove Admin Access',
		type: 'object',
	}
	const message = {
		event: 'Remove Admin Access',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Remove Admin Access',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to "remove scope" in the role details page


 *
 * @param {RemoveScopeFromRole} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removeScopeFromRole(
	props: RemoveScopeFromRole,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks to "remove scope" in the role details page\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					role_name: {
						description:
							"The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['application_selected', 'role_name', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Remove Scope From Role',
		type: 'object',
	}
	const message = {
		event: 'Remove Scope From Role',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Remove Scope From Role',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to cancel the remove scope from role

 *
 * @param {RemoveScopeFromRoleCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removeScopeFromRoleCanceled(
	props: RemoveScopeFromRoleCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user click to cancel the remove scope from role\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					cancel_button: {
						description:
							"Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['cancel_button', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Remove Scope From Role Canceled',
		type: 'object',
	}
	const message = {
		event: 'Remove Scope From Role Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Remove Scope From Role Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the Removing Scope from Role is finished and returns feedback (failure and/or success)





 *
 * @param {RemoveScopeFromRoleSubmissionResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removeScopeFromRoleSubmissionResult(
	props: RemoveScopeFromRoleSubmissionResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the Removing Scope from Role is finished and returns feedback (failure and/or success)\n\n\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					remove_scopes_fail: {
						description:
							"The number of fails scope addition. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					remove_scopes_success: {
						description: "The number of added scopes. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					role_name: {
						description:
							"The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					scope_names: {
						description:
							"The array of scope names. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								scope_name: {
									description:
										"The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'",
									type: ['string', 'null'],
								},
							},
							required: ['scope_name'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'application_selected',
					'remove_scopes_fail',
					'remove_scopes_success',
					'role_name',
					'scope_names',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Remove Scope From Role Submission Result',
		type: 'object',
	}
	const message = {
		event: 'Remove Scope From Role Submission Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Remove Scope From Role Submission Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to "yes, remove" the scopes from the role in the modal



 *
 * @param {RemoveScopeFromRoleSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removeScopeFromRoleSubmitted(
	props: RemoveScopeFromRoleSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user click to "yes, remove" the scopes from the role in the modal\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					role_name: {
						description:
							"The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					scopes_removed: {
						description:
							"The entity name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'application_selected',
					'role_name',
					'scopes_removed',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Remove Scope From Role Submitted',
		type: 'object',
	}
	const message = {
		event: 'Remove Scope From Role Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Remove Scope From Role Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tiggered when the user clicks on User Details page
 *
 * @param {ReporterDetailsRedirection} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reporterDetailsRedirection(
	props: ReporterDetailsRedirection,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Tiggered when the user clicks on User Details page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					reporter_country: {
						description:
							'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						type: ['string', 'null'],
					},
					reporter_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['reporter_country', 'reporter_email', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Reporter Details Redirection',
		type: 'object',
	}
	const message = {
		event: 'Reporter Details Redirection',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Reporter Details Redirection',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tiggered when the user clicks on "copy email"
 *
 * @param {ReporterEmailCopy} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reporterEmailCopy(
	props: ReporterEmailCopy,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Tiggered when the user clicks on "copy email"',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					reporter_country: {
						description:
							'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						type: ['string', 'null'],
					},
					reporter_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['reporter_country', 'reporter_email', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Reporter Email Copy',
		type: 'object',
	}
	const message = {
		event: 'Reporter Email Copy',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Reporter Email Copy',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks to resend invitation
 *
 * @param {ResendCustomerInvitation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resendCustomerInvitation(
	props: ResendCustomerInvitation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks to resend invitation',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_country: {
						description:
							'The country in a given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						type: ['string', 'null'],
					},
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					new_user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_country',
					'account_id',
					'account_name',
					'new_user_email',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Resend Customer Invitation',
		type: 'object',
	}
	const message = {
		event: 'Resend Customer Invitation',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Resend Customer Invitation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tiggered when the user clicks on the Resend Invitation
 *
 * @param {ResendUserInvitation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resendUserInvitation(
	props: ResendUserInvitation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Tiggered when the user clicks on the Resend Invitation',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_app: {
						description:
							'The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: ['country', 'screen_name', 'user_app', 'user_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Resend User Invitation',
		type: 'object',
	}
	const message = {
		event: 'Resend User Invitation',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Resend User Invitation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to create new scope

 *
 * @param {RoleCreationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function roleCreationCanceled(
	props: RoleCreationCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click to create new scope\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					cancel_button: {
						description:
							"Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['cancel_button', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Role Creation Canceled',
		type: 'object',
	}
	const message = {
		event: 'Role Creation Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Role Creation Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the Role Creation is finished and returns feedback (failure and/or success)



 *
 * @param {RoleCreationResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function roleCreationResult(
	props: RoleCreationResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the Role Creation is finished and returns feedback (failure and/or success)\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					role_creation: {
						description:
							"The role was created or not. It's a boolean. If trigarred, set True. If not, set False.",
						type: ['boolean', 'null'],
					},
					role_name: {
						description:
							"The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'application_selected',
					'role_creation',
					'role_name',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Role Creation Result',
		type: 'object',
	}
	const message = {
		event: 'Role Creation Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Role Creation Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to create new scope
 *
 * @param {RoleCreationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function roleCreationStarted(
	props: RoleCreationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click to create new scope',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Role Creation Started',
		type: 'object',
	}
	const message = {
		event: 'Role Creation Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Role Creation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to create new role in the modal


 *
 * @param {RoleCreationSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function roleCreationSubmitted(
	props: RoleCreationSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user click to create new role in the modal\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					role_name: {
						description:
							"The role name in a given context. Permitted values. Samples: 'IdentityFE.Permissions'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['application_selected', 'role_name', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Role Creation Submitted',
		type: 'object',
	}
	const message = {
		event: 'Role Creation Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Role Creation Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to delete a role
 *
 * @param {RoleDeletion} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function roleDeletion(
	props: RoleDeletion,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click to delete a role',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					scope_name: {
						description:
							"The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['application_selected', 'scope_name', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Role Deletion',
		type: 'object',
	}
	const message = {
		event: 'Role Deletion',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Role Deletion',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to cancel the scope creation

 *
 * @param {ScopeCreationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scopeCreationCanceled(
	props: ScopeCreationCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click to cancel the scope creation\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					cancel_button: {
						description:
							"Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['cancel_button', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Scope Creation Canceled',
		type: 'object',
	}
	const message = {
		event: 'Scope Creation Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Scope Creation Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the Scope Creation is finished and returns feedback (failure and/or success)



 *
 * @param {ScopeCreationResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scopeCreationResult(
	props: ScopeCreationResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the Scope Creation is finished and returns feedback (failure and/or success)\n\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					scope_creation: {
						description:
							"The scope was created or not. It's a boolean. If trigarred, set True. If not, set False.",
						type: ['boolean', 'null'],
					},
					scope_name: {
						description:
							"The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'application_selected',
					'scope_creation',
					'scope_name',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Scope Creation Result',
		type: 'object',
	}
	const message = {
		event: 'Scope Creation Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Scope Creation Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to create new scope
 *
 * @param {ScopeCreationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scopeCreationStarted(
	props: ScopeCreationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click to create new scope',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Scope Creation Started',
		type: 'object',
	}
	const message = {
		event: 'Scope Creation Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Scope Creation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to create new scope in the modal


 *
 * @param {ScopeCreationSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scopeCreationSubmitted(
	props: ScopeCreationSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user click to create new scope in the modal\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					scope_name: {
						description:
							"The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['application_selected', 'scope_name', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Scope Creation Submitted',
		type: 'object',
	}
	const message = {
		event: 'Scope Creation Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Scope Creation Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to delete a scope
 *
 * @param {ScopeDeletion} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function scopeDeletion(
	props: ScopeDeletion,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click to delete a scope',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					scope_name: {
						description:
							"The scope name in a given context. Permitted values. Samples: 'IdentityFE.Permission.Write'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['application_selected', 'scope_name', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Scope Deletion',
		type: 'object',
	}
	const message = {
		event: 'Scope Deletion',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Scope Deletion',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user accesses the Bulk Action and select a tab


 *
 * @param {TabSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tabSelected(
	props: TabSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user accesses the Bulk Action and select a tab\n\n',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['file_type', 'listed_items', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Tab Selected',
		type: 'object',
	}
	const message = {
		event: 'Tab Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Tab Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "download template" button on the home page
 *
 * @param {TemplateDownloadedHome} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function templateDownloadedHome(
	props: TemplateDownloadedHome,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "download template" button on the home page',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['file_type', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Template Downloaded Home',
		type: 'object',
	}
	const message = {
		event: 'Template Downloaded Home',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Template Downloaded Home',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "download template" button on the modal
 *
 * @param {TemplateDownloadedModal} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function templateDownloadedModal(
	props: TemplateDownloadedModal,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "download template" button on the modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					file_type: {
						description:
							"The file type selected. Which variable the user is using to perform the filtering action. Samples. 'Manage Customer User', 'Manage Link User', 'Edit Admin User', 'contract to contract', 'contract to account', 'dtaas only'.",
						enum: [
							'EDIT_ADMIN_USE',
							'MANAGE_CUSTOMER_USER',
							'MANAGE_LINK_USER',
							'CONTRACT_TO_CONTRACT',
							'CONTRACT_TO_ACCOUNT',
							'DTAAS_ONLY',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					vendor_id: {
						description:
							'The vendor ID selected to manage customer users. Sample: 20210111-100902-1841701',
						type: ['string', 'null'],
					},
				},
				required: ['file_type', 'screen_name', 'vendor_id'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Template Downloaded Modal',
		type: 'object',
	}
	const message = {
		event: 'Template Downloaded Modal',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Template Downloaded Modal',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user cancels user block
 *
 * @param {UserBlockCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userBlockCanceled(
	props: UserBlockCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user cancels user block',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					cancel_button: {
						description:
							"Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['cancel_button', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Block Canceled',
		type: 'object',
	}
	const message = {
		event: 'User Block Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Block Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user status is blocked by an admin user
 *
 * @param {UserBlocked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userBlocked(
	props: UserBlocked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user status is blocked by an admin user',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					admin_affiliation: {
						description:
							'The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					admin_permission: {
						description:
							"The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								admin_permissions: {
									description:
										'The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst',
									type: ['string', 'null'],
								},
							},
							required: ['admin_permissions'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					user_app: {
						description:
							'The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'admin_permission',
					'admin_affiliation',
					'country',
					'user_email',
					'user_phone',
					'user_status',
					'user_id',
					'user_app',
					'user_affiliation',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Blocked',
		type: 'object',
	}
	const message = {
		event: 'User Blocked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Blocked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on User Chart button and open the chart
 *
 * @param {UserChartViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userChartViewed(
	props: UserChartViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on User Chart button and open the chart',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Chart Viewed',
		type: 'object',
	}
	const message = {
		event: 'User Chart Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Chart Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Listing position the user was in when clicked
 *
 * @param {UserClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userClicked(
	props: UserClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: '\nListing position the user was in when clicked',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					clicked_item_position: {
						description: 'The number of pages loaded. Samples: 10, 15, 30',
						type: ['integer', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['clicked_item_position', 'listed_items', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Clicked',
		type: 'object',
	}
	const message = {
		event: 'User Clicked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the Details tab of the User Details page loads
 *
 * @param {UserDetailsViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userDetailsViewed(
	props: UserDetailsViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the Details tab of the User Details page loads',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					admin_affiliation: {
						description:
							'The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					admin_permission: {
						description:
							"The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								admin_permissions: {
									description:
										'The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst',
									type: ['string', 'null'],
								},
							},
							required: ['admin_permissions'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					user_app: {
						description:
							'The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'admin_permission',
					'admin_affiliation',
					'country',
					'user_email',
					'user_phone',
					'user_status',
					'user_id',
					'user_app',
					'user_affiliation',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Details Viewed',
		type: 'object',
	}
	const message = {
		event: 'User Details Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Details Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user cancels the User Edit Setup journey by clicking on the "Cancel" button on the Edit User Information Modal
 *
 * @param {UserEditingCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userEditingCanceled(
	props: UserEditingCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user cancels the User Edit Setup journey by clicking on the "Cancel" button on the Edit User Information Modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Editing Canceled',
		type: 'object',
	}
	const message = {
		event: 'User Editing Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Editing Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the User Edit Setup journey by clicking on the "Edit" button on the User Details Tab
 *
 * @param {UserEditingStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userEditingStarted(
	props: UserEditingStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the User Edit Setup journey by clicking on the "Edit" button on the User Details Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Editing Started',
		type: 'object',
	}
	const message = {
		event: 'User Editing Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Editing Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the User Edit process is finished and returns feedback (failure and/or success)
 *
 * @param {UserEditingSubmissionResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userEditingSubmissionResult(
	props: UserEditingSubmissionResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the User Edit process is finished and returns feedback (failure and/or success)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_remove_permission: {
						description:
							"The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					associated_accounts: {
						description: "The number of associated accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					block_permission: {
						description:
							"The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					companies: {
						description:
							"The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								company_ids: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: ['company_ids'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					company_association_fail_count: {
						description:
							'The number of fails company associantion. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					country_association_fail_count: {
						description:
							'The number of fails supported country association. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					ddc_ids: {
						description:
							"The array of ddcs Ids. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								ddc_id: {
									description: 'The DDC id generated. Sample: BK32',
									type: ['string', 'null'],
								},
							},
							required: ['ddc_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					delivery: {
						description:
							"The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								delivery_center_id: {
									description: 'The delivery center id generated. Sample: BK32',
									type: ['string', 'null'],
								},
							},
							required: ['delivery_center_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					edit_permission: {
						description:
							"The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					group_association_fail_count: {
						description:
							'The number of fails group associations. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					logistic: {
						description:
							"The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								logistic_operator_id: {
									description: 'The Logistic operator ID. Sample: 460751',
									type: ['string', 'null'],
								},
							},
							required: ['logistic_operator_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					permissions: {
						description:
							"The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								permissions_groups: {
									description:
										"Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
									type: ['string', 'null'],
								},
							},
							required: ['permissions_groups'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					route_id: {
						description:
							'The Route ID used for force users. Sample: 20210111-100902-1841701',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					supported_country: {
						description:
							"The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								supported_countries: {
									description:
										'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
									type: ['string', 'null'],
								},
							},
							required: ['supported_countries'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'account_remove_permission',
					'associated_accounts',
					'block_permission',
					'companies',
					'company_association_fail_count',
					'country',
					'country_association_fail_count',
					'ddc_ids',
					'delivery',
					'edit_permission',
					'group_association_fail_count',
					'logistic',
					'permissions',
					'route_id',
					'screen_name',
					'supported_country',
					'user_email',
					'user_id',
					'user_phone',
					'user_status',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Editing Submission Result',
		type: 'object',
	}
	const message = {
		event: 'User Editing Submission Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Editing Submission Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * 
Triggered when the user finishes to fill-out the user information forms and clicks on the "Save Changes" button on the Edit User Information Modal
 *
 * @param {UserEditingSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userEditingSubmitted(
	props: UserEditingSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'\nTriggered when the user finishes to fill-out the user information forms and clicks on the "Save Changes" button on the Edit User Information Modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_remove_permission: {
						description:
							"The button 'remove' is enable. It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					associated_accounts: {
						description: "The number of associated accounts. Sample: '1, 3, 5'",
						type: ['integer', 'null'],
					},
					block_permission: {
						description:
							"The button 'block' or 'unblock' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					companies: {
						description:
							"The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								company_ids: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: ['company_ids'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					delivery: {
						description:
							"The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								delivery_center_id: {
									description: 'The delivery center id generated. Sample: BK32',
									type: ['string', 'null'],
								},
							},
							required: ['delivery_center_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					edit_permission: {
						description:
							"The button 'edit' is enable.It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					logistic: {
						description:
							"The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								logistic_operator_id: {
									description: 'The Logistic operator ID. Sample: 460751',
									type: ['string', 'null'],
								},
							},
							required: ['logistic_operator_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					permissions: {
						description:
							"The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								permissions_groups: {
									description:
										"Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
									type: ['string', 'null'],
								},
							},
							required: ['permissions_groups'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					route_id: {
						description:
							'The Route ID used for force users. Sample: 20210111-100902-1841701',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					supported_country: {
						description:
							"The array of contries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								supported_countries: {
									description:
										'The country in the given context. Sample: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
									type: ['string', 'null'],
								},
							},
							required: ['supported_countries'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'account_remove_permission',
					'associated_accounts',
					'block_permission',
					'companies',
					'country',
					'delivery',
					'edit_permission',
					'logistic',
					'permissions',
					'route_id',
					'screen_name',
					'supported_country',
					'user_email',
					'user_id',
					'user_phone',
					'user_status',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Editing Submitted',
		type: 'object',
	}
	const message = {
		event: 'User Editing Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Editing Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the copy icon to copy the value
 *
 * @param {UserInfoCopied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInfoCopied(
	props: UserInfoCopied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the copy icon to copy the value',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					copied_criteria: {
						description:
							"'Copied value in the given context. Sample: 'ID' , 'phone', 'email'",
						enum: ['EMAIL', 'ID', 'PHONE', null],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['copied_criteria', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Info Copied',
		type: 'object',
	}
	const message = {
		event: 'User Info Copied',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Info Copied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "X" button
 *
 * @param {UserInvitationCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationCanceled(
	props: UserInvitationCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on the "X" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Invitation Canceled',
		type: 'object',
	}
	const message = {
		event: 'User Invitation Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Invitation Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects the "One" or "force" option
 *
 * @param {UserInvitationCategorySelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationCategorySelected(
	props: UserInvitationCategorySelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user selects the "One" or "force" option',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					invitation_category: {
						description:
							'The invitation category selected. Permitted Values.ONE or FORCE',
						enum: ['ONE', 'FORCE'],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'invitation_category'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Invitation Category Selected',
		type: 'object',
	}
	const message = {
		event: 'User Invitation Category Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Invitation Category Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "Done" button
 *
 * @param {UserInvitationFinished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationFinished(
	props: UserInvitationFinished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on the "Done" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Invitation Finished',
		type: 'object',
	}
	const message = {
		event: 'User Invitation Finished',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Invitation Finished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "Add new user" button
 *
 * @param {UserInvitationRestarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationRestarted(
	props: UserInvitationRestarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on the "Add new user" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Invitation Restarted',
		type: 'object',
	}
	const message = {
		event: 'User Invitation Restarted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Invitation Restarted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the process of the Admin User Invitation is finished and returns feedback (failure and/or success)
 *
 * @param {UserInvitationResult} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationResult(
	props: UserInvitationResult,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the process of the Admin User Invitation is finished and returns feedback (failure and/or success)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					association_fail_count: {
						description: 'The number of fails associations. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					association_success_count: {
						description: 'The number of success associations. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					invitation_category: {
						description:
							'The invitation category selected. Permitted Values.ONE or FORCE',
						enum: ['ONE', 'FORCE'],
						type: ['string', 'null'],
					},
					invitation_fail_count: {
						description: 'The number of fails invitations. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					invitation_success_count: {
						description: 'The number of success invitations. Sample: 1,2,3,4',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'association_fail_count',
					'association_success_count',
					'invitation_fail_count',
					'invitation_success_count',
					'invitation_category',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Invitation Result',
		type: 'object',
	}
	const message = {
		event: 'User Invitation Result',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Invitation Result',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the User Invitation journey by clicking on the "Add User" button on the User List Tab
 *
 * @param {UserInvitationStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationStarted(
	props: UserInvitationStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the User Invitation journey by clicking on the "Add User" button on the User List Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Invitation Started',
		type: 'object',
	}
	const message = {
		event: 'User Invitation Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Invitation Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "finish" button
 *
 * @param {UserInvitationSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationSubmitted(
	props: UserInvitationSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on the "finish" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					companies: {
						description:
							"The array of companies. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								company_ids: {
									description:
										"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
									type: ['string', 'null'],
								},
							},
							required: ['company_ids'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					countries: {
						description:
							"The array of countries. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								supported_country: {
									description:
										'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
									enum: [
										'AQ',
										'AR',
										'BB',
										'BE',
										'BO',
										'BR',
										'CA',
										'CL',
										'CO',
										'CZ',
										'DE',
										'DO',
										'ES',
										'EC',
										'GB',
										'HN',
										'IN',
										'ID',
										'KR',
										'MX',
										'NL',
										'NG',
										'PA',
										'PY',
										'PE',
										'PH',
										'TH',
										'TZ',
										'UG',
										'US',
										'UY',
										'ZA',
										null,
									],
									type: ['string', 'null'],
								},
							},
							required: ['supported_country'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					delivery: {
						description:
							"The array of delivery. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								delivery_center_id: {
									description: 'The delivery center id generated. Sample: BK32',
									type: ['string', 'null'],
								},
							},
							required: ['delivery_center_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					invitation_category: {
						description:
							'The invitation category selected. Permitted Values.ONE or FORCE',
						enum: ['ONE', 'FORCE'],
						type: ['string', 'null'],
					},
					logistic: {
						description:
							"The array of logistic. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								logistic_operator_id: {
									description: 'The Logistic operator ID. Sample: 460751',
									type: ['string', 'null'],
								},
							},
							required: ['logistic_operator_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					new_user_info: {
						description:
							'The array of new user information as email, manager and supervisor',
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								manager_email: {
									description:
										'The manager email in a given context. Permitted values. Samples. isabela.nogueira@ab-inbev.com',
									type: ['string', 'null'],
								},
								supervisors_emails: {
									description:
										'The list of supervisors emails in a given context. Permitted values. Samples. isabela.nogueira@ab-inbev.com, isabela@gmail.com',
									type: ['string', 'null'],
								},
								user_email: {
									description:
										'The user email in a given context. Permitted values. Samples. isabela.nogueira@ab-inbev.com',
									type: ['string', 'null'],
								},
							},
							required: ['user_email', 'manager_email', 'supervisors_emails'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					permissions: {
						description:
							"The array of ipermissions.. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								permissions_groups: {
									description:
										"Permissions groups of the user in the given context. Samples: 'Admin Invitation General'",
									type: ['string', 'null'],
								},
							},
							required: ['permissions_groups'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					route_id: {
						description:
							'The Route ID used for force users. Sample: 20210111-100902-1841701',
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'companies',
					'countries',
					'country',
					'delivery',
					'logistic',
					'permissions',
					'route_id',
					'invitation_category',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Invitation Submitted',
		type: 'object',
	}
	const message = {
		event: 'User Invitation Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Invitation Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user accesses the User Inviation Tab
 *
 * @param {UserInvitationViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInvitationViewed(
	props: UserInvitationViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user accesses the User Inviation Tab ',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Invitation Viewed',
		type: 'object',
	}
	const message = {
		event: 'User Invitation Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Invitation Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on "..." and select an action
 *
 * @param {UserListActions} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userListActions(
	props: UserListActions,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user clicks on "..." and select an action',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					action_selected: {
						description:
							'The action in the given context. Permitted Values: User Details, Remove User',
						enum: ['REMOVE_USER', 'USER_DETAILS', null],
						type: ['string', 'null'],
					},
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					group_name: {
						description:
							"The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_selected: {
						description:
							"The user email in a given context. Permitted values. Samples: 'isabela@gmail.com'",
						type: ['string', 'null'],
					},
				},
				required: [
					'action_selected',
					'application_selected',
					'group_name',
					'screen_name',
					'user_selected',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User List Actions',
		type: 'object',
	}
	const message = {
		event: 'User List Actions',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User List Actions',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to filter the user list
 *
 * @param {UserListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userListFiltered(
	props: UserListFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click to filter the user list',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					country_filtered: {
						description:
							'The country in the given context. Permitted values: All,Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						type: ['string', 'null'],
					},
					group_name: {
						description:
							"The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'",
						type: ['string', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'application_selected',
					'country_filtered',
					'group_name',
					'listed_items',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User List Filtered',
		type: 'object',
	}
	const message = {
		event: 'User List Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user scrolls down to the next page on the user list (only when the next pagination response will be loaded)
 *
 * @param {UserListPagination} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userListPagination(
	props: UserListPagination,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user scrolls down to the next page on the user list (only when the next pagination response will be loaded)',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					clicked_item_position: {
						description: 'The number of pages loaded. Samples: 10, 15, 30',
						type: ['integer', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					information: {
						description:
							"The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								user_email: {
									description:
										'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
									type: ['string', 'null'],
								},
								user_phone: {
									description:
										'The user phone number in the given context. Samples: +5519999841766',
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked',
									enum: ['ACTIVE', 'BLOCKED', 'TO BE ONBOARDED', null],
									type: ['string', 'null'],
								},
							},
							required: ['user_email', 'user_phone', 'user_status'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					search_criteria: {
						description:
							"The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', \"phone', 'email', 'other'",
						enum: ['EMAIL', 'ID', 'NAME', 'OTHER', 'PHONE'],
						type: ['string'],
					},
				},
				required: [
					'clicked_item_position',
					'country',
					'information',
					'listed_items',
					'screen_name',
					'search_criteria',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User List Pagination',
		type: 'object',
	}
	const message = {
		event: 'User List Pagination',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User List Pagination',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects terms to search a list based on a given criteria
 *
 * @param {UserListSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userListSearched(
	props: UserListSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects terms to search a list based on a given criteria',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					group_name: {
						description:
							"The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'",
						type: ['string', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					search_criteria: {
						description:
							"The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', \"phone', 'email', 'other'",
						enum: ['EMAIL', 'ID', 'NAME', 'OTHER', 'PHONE'],
						type: ['string'],
					},
				},
				required: [
					'application_selected',
					'group_name',
					'listed_items',
					'screen_name',
					'search_criteria',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User List Searched',
		type: 'object',
	}
	const message = {
		event: 'User List Searched',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User List Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to show the user list
 *
 * @param {UserListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userListViewed(
	props: UserListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click to show the user list',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					information: {
						description:
							"The array of information. Attention: Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								user_email: {
									description:
										'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
									type: ['string', 'null'],
								},
								user_phone: {
									description:
										'The user phone number in the given context. Samples: +5519999841766',
									type: ['string', 'null'],
								},
								user_status: {
									description:
										'The user status in a given context. Permitted values. Samples: Active, To be onboarded, Blocked',
									enum: ['ACTIVE', 'BLOCKED', 'TO BE ONBOARDED', null],
									type: ['string', 'null'],
								},
							},
							required: ['user_email', 'user_phone', 'user_status'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					listed_items: {
						description:
							'The number of items listed in the given context. Samples: 3, 8, 15',
						type: ['integer', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					search_criteria: {
						description:
							"The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', \"phone', 'email', 'other'",
						enum: ['EMAIL', 'ID', 'NAME', 'OTHER', 'PHONE'],
						type: ['string'],
					},
					user_type: {
						description:
							"The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'",
						enum: ['CUSTOMER', 'ONE', 'FORCE', 'DTC', null],
						type: ['string', 'null'],
					},
				},
				required: [
					'information',
					'user_type',
					'search_criteria',
					'listed_items',
					'country',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User List Viewed',
		type: 'object',
	}
	const message = {
		event: 'User List Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the User Removal journey by clicking on the "Remove" button on the Account Details Tab
 *
 * @param {UserRemovalStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userRemovalStarted(
	props: UserRemovalStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the User Removal journey by clicking on the "Remove" button on the Account Details Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Removal Started',
		type: 'object',
	}
	const message = {
		event: 'User Removal Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Removal Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user clicks on the "Remove" button on the User Removal Modal
 *
 * @param {UserRemovalSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userRemovalSubmitted(
	props: UserRemovalSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user clicks on the "Remove" button on the User Removal Modal',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					account_id: {
						description:
							"The account ID in a given context. Permitted values. Samples: 'H8999'",
						type: ['string', 'null'],
					},
					account_name: {
						description:
							"The user name in a given context. Permitted values. Samples: '7 STAR CONVENIENCE STORE'",
						type: ['string', 'null'],
					},
					removed_user_ids: {
						description:
							"The array of removed user ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								removed_user_id: {
									description:
										'The removed user ids. Sample. 6096c481; 9238748932; 309128932',
									type: ['string', 'null'],
								},
							},
							required: ['removed_user_id'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: [
					'account_id',
					'account_name',
					'removed_user_ids',
					'screen_name',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Removal Submitted',
		type: 'object',
	}
	const message = {
		event: 'User Removal Submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Removal Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects terms to search a list based on a given criteria
 *
 * @param {UserSearchCriteria} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userSearchCriteria(
	props: UserSearchCriteria,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user selects terms to search a list based on a given criteria',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					search_criteria: {
						description:
							"The column that is being searched. Which variable the user is using to perform the filtering action. Samples: 'Name', 'ID', \"phone', 'email', 'other'",
						enum: ['EMAIL', 'ID', 'NAME', 'OTHER', 'PHONE'],
						type: ['string'],
					},
					user_type: {
						description:
							"The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'",
						enum: ['CUSTOMER', 'ONE', 'FORCE', 'DTC', null],
						type: ['string', 'null'],
					},
				},
				required: ['user_type', 'screen_name', 'search_criteria'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Search Criteria',
		type: 'object',
	}
	const message = {
		event: 'User Search Criteria',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Search Criteria',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects the user type
 *
 * @param {UserTypeSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userTypeSelected(
	props: UserTypeSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user selects the user type',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_type: {
						description:
							"The value chosen to filter the list. Which variable the user is using to perform the filtering action. Samples: 'Hub' , 'Customer'",
						enum: ['CUSTOMER', 'ONE', 'FORCE', 'DTC', null],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'user_type'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Type Selected',
		type: 'object',
	}
	const message = {
		event: 'User Type Selected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Type Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user cancels user unblock
 *
 * @param {UserUnblockCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userUnblockCanceled(
	props: UserUnblockCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user cancels user unblock',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					cancel_button: {
						description:
							"Cancel by clicking on 'X' or 'Cancel\". It's a boolean. If triggared, set True. If not, set False",
						type: ['boolean', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['cancel_button', 'screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Unblock Canceled',
		type: 'object',
	}
	const message = {
		event: 'User Unblock Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Unblock Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user status is unblocked by an admin user
 *
 * @param {UserUnblocked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userUnblocked(
	props: UserUnblocked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user status is unblocked by an admin user',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					admin_affiliation: {
						description:
							'The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					admin_permission: {
						description:
							"The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								admin_permissions: {
									description:
										'The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst',
									type: ['string', 'null'],
								},
							},
							required: ['admin_permissions'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					user_app: {
						description:
							'The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin',
						type: ['string', 'null'],
					},
					user_email: {
						description:
							'The user email in a given context. Permitted values. Samples: isabela.nogueira@ab-inbev.com',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
					user_phone: {
						description:
							'The user phone number in the given context. Samples: +5519999841766',
						type: ['string', 'null'],
					},
					user_status: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'admin_permission',
					'admin_affiliation',
					'country',
					'user_email',
					'user_phone',
					'user_status',
					'user_id',
					'user_app',
					'user_affiliation',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Unblocked',
		type: 'object',
	}
	const message = {
		event: 'User Unblocked',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Unblocked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user accesses the User Tab
 *
 * @param {UserViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userViewed(
	props: UserViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user accesses the User Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User Viewed',
		type: 'object',
	}
	const message = {
		event: 'User Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user click to extract the user list
 *
 * @param {UserExtractionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userExtractionStarted(
	props: UserExtractionStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user click to extract the user list',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					application_selected: {
						description:
							"The value chosen to start the experience. Samples: 'PIM' , 'Identity'",
						type: ['string', 'null'],
					},
					group_name: {
						description:
							"The group name in a given context. Permitted values. Samples: 'IdentityFE.Permission Manager'",
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'application_selected', 'group_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User extraction started',
		type: 'object',
	}
	const message = {
		event: 'User extraction started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User extraction started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user cancels the User Info Edit by clicking on the "cancel" button
 *
 * @param {UserInformationEditCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInformationEditCanceled(
	props: UserInformationEditCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user cancels the User Info Edit by clicking on the "cancel" button',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User information edit canceled',
		type: 'object',
	}
	const message = {
		event: 'User information edit canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User information edit canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user starts the User Info Edit  by clicking on the "Edit user" button on the User Details Tab
 *
 * @param {UserInformationEditStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInformationEditStarted(
	props: UserInformationEditStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user starts the User Info Edit  by clicking on the "Edit user" button on the User Details Tab',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User information edit started',
		type: 'object',
	}
	const message = {
		event: 'User information edit started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User information edit started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user finishes to fill out the edit user page and save changes
 *
 * @param {UserInformationEditSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function userInformationEditSubmitted(
	props: UserInformationEditSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when the user finishes to fill out the edit user page and save changes',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					admin_affiliation: {
						description:
							'The logged user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					admin_permission: {
						description:
							"The array of permissions of the logeed user. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the Protocols UI",
						items: {
							description:
								"Do not implement with the 'items' structure/object, it's a normal array",
							properties: {
								admin_permissions: {
									description:
										'The array of IdentityFE permissions the logged have. Sample. BEES Admin, BEES Manager, BEES Partner manager, BEES Country manager, External manager, BEES Analyst',
									type: ['string', 'null'],
								},
							},
							required: ['admin_permissions'],
							type: ['object'],
						},
						type: ['array', 'null'],
					},
					country: {
						description:
							'To specify the selected country. Permitted values: AR, BR, CA, DR, EC, SV, HN, MX, PA, PY, PE, ZA, TZ, US, UY',
						enum: [
							'AQ',
							'AR',
							'BB',
							'BE',
							'BO',
							'BR',
							'CA',
							'CL',
							'CO',
							'CZ',
							'DE',
							'DO',
							'ES',
							'EC',
							'GB',
							'HN',
							'IN',
							'ID',
							'KR',
							'MX',
							'NL',
							'NG',
							'PA',
							'PY',
							'PE',
							'PH',
							'TH',
							'TZ',
							'UG',
							'US',
							'UY',
							'ZA',
							null,
						],
						type: ['string', 'null'],
					},
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					user_affiliation: {
						description:
							'The edited user affiliation. Sample. ABI, GLobal Growth, Seller DTaaS, Seller 3P PRO, Manufacturer',
						enum: [
							'GLOBAL GROWTH',
							'ABI',
							'SELLER DTAAS',
							'SELLER 3P PRO',
							'MANUFACTURER',
							null,
						],
						type: ['string', 'null'],
					},
					user_app: {
						description:
							'The app where the user was created. Sample. B2B, adminportal, force, oncustomer, deliveradmin',
						type: ['string', 'null'],
					},
					user_id: {
						description:
							"The company's id generated in the vendor service. Sample: 6096c481-a3c5-4cf4-9c4b-8a2d08dac26e",
						type: ['string', 'null'],
					},
				},
				required: [
					'screen_name',
					'admin_permission',
					'admin_affiliation',
					'country',
					'user_id',
					'user_app',
					'user_affiliation',
				],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'User information edit submitted',
		type: 'object',
	}
	const message = {
		event: 'User information edit submitted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'User information edit submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user filters the list by wholesaler
 *
 * @param {WholesalerFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function wholesalerFiltered(
	props: WholesalerFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when the user filters the list by wholesaler',
		labels: {},
		properties: {
			context: {},
			properties: {
				properties: {
					screen_name: {
						description:
							'The name of the screen where the user is taking this action. Permitted Values. USERS, USER_DETAILS,ACCOUNTS ACCOUNT_DETAILS ADMIN_USER_INVITATION BULK CUSTOMER_USER_INVITATION, USERS, PERMISSIONS, USER_DETAILS, USERS_CHART, LINK_ACCOUNT',
						enum: [
							'ACCOUNTS',
							'ACCOUNT_DETAILS',
							'USER_INVITATION',
							'BULK',
							'CUSTOMER_USER_INVITATION',
							'USERS',
							'PERMISSIONS',
							'USER_DETAILS',
							'USERS_CHART',
							'LINK_ACCOUNT',
							'AFFILIATIONS_AND_GROUPS',
							null,
						],
						type: ['string', 'null'],
					},
					wholesaler_country: {
						description:
							'The country in the given context. Permitted values: Argentina,Brazil,Canada,Colombia,Dominican Republic,Ecuador,El Salvador,Honduras,Mexico,Panama,Paraguay,Peru,South Africa,South Korea,Tanzania,Uganda,United Kingdom,United States,Uruguay',
						enum: [
							'AR',
							'BR',
							'CA',
							'CO',
							'DR',
							'EC',
							'HN',
							'KR',
							'MX',
							'PA',
							'PE',
							'PY',
							'SV',
							'TZ',
							'UG',
							'UK',
							'US',
							'UY',
							'ZA',
						],
						type: ['string'],
					},
					wholesaler_filter: {
						description:
							"The column that is being filtered. Which variable the user is using to perform the filtering action. Permitted Values: 'Wholesaler/Branch'",
						enum: ['BRANCH', 'WHOLESALER', null],
						type: ['string', 'null'],
					},
				},
				required: ['screen_name', 'wholesaler_country', 'wholesaler_filter'],
				type: 'object',
			},
			traits: {},
		},
		required: ['properties'],
		title: 'Wholesaler Filtered',
		type: 'object',
	}
	const message = {
		event: 'Wholesaler Filtered',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Wholesaler Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Listing position the account was in when clicked
	 *
	 * @param {AccountClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountClicked,
	/**
	 * Triggered when user deselects an account
	 *
	 * @param {AccountDeselection} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountDeselection,
	/**
	 * Triggered when the Details tab of the Account Details page loads
	 *
	 * @param {AccountDetailsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountDetailsViewed,
	/**
	 * Trigger when the user execute a new file
	 *
	 * @param {AccountLinkingExecutionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountLinkingExecutionStarted,
	/**
	 * Trigger when the user validate a new file
	 *
	 * @param {AccountLinkingValidationStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountLinkingValidationStarted,
	/**
	 * Triggered when the user selects terms to search a list based on a given criteria
	 *
	 * @param {AccountListSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountListSearched,
	/**
	 * Triggered when the user accesses the Account Tab and sees the list of accounts
	 *
	 * @param {AccountListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountListViewed,
	/**
	 * 
	Triggered when the user cancels the User Removal journey by clicking on the "Cancel" button on the Confirmation Modal 
	 *
	 * @param {AccountRemovalCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountRemovalCanceled,
	/**
	 * 
	Triggered when the user starts the Account Removal journey by clicking on the "Remove" button on the User Details Tab
	 *
	 * @param {AccountRemovalStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountRemovalStarted,
	/**
	 * 
	Triggered when the process of the User Removal is finished and returns feedback (failure and/or success)
	 *
	 * @param {AccountRemovalSubmissionResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountRemovalSubmissionResult,
	/**
	 * Triggered when the user clicks on the "Remove" button on the Confirmation Modal
	 *
	 * @param {AccountRemovalSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountRemovalSubmitted,
	/**
	 * Triggered when user selects accounts
	 *
	 * @param {AccountSelection} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountSelection,
	/**
	 * Triggered when the user clicks to add the Admin access
	 *
	 * @param {AddAdminAccess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addAdminAccess,
	/**
	 * Triggered when the user starts the User Invitation journey by clicking on the "Add " button on the Account Details Tab
	 *
	 * @param {AddNewUserStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addNewUserStarted,
	/**
	 * Triggered when the user clicks to "add scope" in the role details page
	
	
	 *
	 * @param {AddScopeToRole} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addScopeToRole,
	/**
	 * Triggered when the user click to cancel the add scope to role
	
	 *
	 * @param {AddScopeToRoleCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addScopeToRoleCanceled,
	/**
	 * Triggered when the process of the Add Scope to Role is finished and returns feedback (failure and/or success)
	
	
	
	
	
	 *
	 * @param {AddScopeToRoleSubmissionResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addScopeToRoleSubmissionResult,
	/**
	 * Triggered when the user click to "add" the scopes to the role in the modal
	
	
	
	 *
	 * @param {AddScopeToRoleSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	addScopeToRoleSubmitted,
	/**
	 * Triggered when the user selects the "Administrator" option
	 *
	 * @param {AdminUserCategorySelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adminUserCategorySelected,
	/**
	 * Triggered when the user clicks on the "back" button
	 *
	 * @param {AdminUserInvitationCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adminUserInvitationCanceled,
	/**
	 * Triggered when the user clicks on the "Done" button
	 *
	 * @param {AdminUserInvitationFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adminUserInvitationFinished,
	/**
	 * Triggered when the user clicks on the "Add new user" button
	 *
	 * @param {AdminUserInvitationRestarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adminUserInvitationRestarted,
	/**
	 * Triggered when the process of the Admin User Invitation is finished and returns feedback (failure and/or success)
	 *
	 * @param {AdminUserInvitationResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adminUserInvitationResult,
	/**
	 * Triggered when the user clicks on the "finish" button
	 *
	 * @param {AdminUserInvitationSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	adminUserInvitationSubmitted,
	/**
	 * Triggered when user clicks on 'Select All'
	 *
	 * @param {AllAccountSelection} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	allAccountSelection,
	/**
	 * Triggered when user clicks on 'Deselect All'
	 *
	 * @param {AllUserDeselection} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	allUserDeselection,
	/**
	 * Triggered when the user filters the list by application
	 *
	 * @param {ApplicationFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationFiltered,
	/**
	 * Triggered when the user selects the application
	
	
	 *
	 * @param {ApplicationSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	applicationSelected,
	/**
	 * Triggered when the user accesses the Bulk Action Tab
	 *
	 * @param {BulkActionsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	bulkActionsViewed,
	/**
	 * Triggered when the user starts the Change Settings journey by clicking on the "Change Purchase" button.
	 *
	 * @param {ChangePurchaseExperienceStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	changePurchaseExperienceStarted,
	/**
	 * Tiggered when the user clicks on the arrow or the reporter card
	 *
	 * @param {ChartNavigation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	chartNavigation,
	/**
	 * Triggered when the user clicks to resend invitation
	 *
	 * @param {CopyCustomerRegistrationLink} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	copyCustomerRegistrationLink,
	/**
	 * Triggered when the user clicks to resend invitation
	 *
	 * @param {CopyUserRegistrationLink} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	copyUserRegistrationLink,
	/**
	 * 
	Triggered when the user filters the list by Country
	 *
	 * @param {CountryFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	countryFiltered,
	/**
	 * Triggered when the user cancels the User Invitation journey by clicking on the "Back" button on the User Invitation Modal
	 *
	 * @param {CustomerUserInvitationCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerUserInvitationCanceled,
	/**
	 * Tiggered when the user clicks on the "Done" button
	 *
	 * @param {CustomerUserInvitationFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerUserInvitationFinished,
	/**
	 * Tiggered when the user clicks on the "Add new user" button
	 *
	 * @param {CustomerUserInvitationRestarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerUserInvitationRestarted,
	/**
	 * Triggered when the process of the User Invitation is failed.
	 *
	 * @param {CustomerUserInvitationResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerUserInvitationResult,
	/**
	 * Triggered when the user finishes to fill-out the user invitation forms and clicks on the "Finish" button
	 *
	 * @param {CustomerUserInvitationSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customerUserInvitationSubmitted,
	/**
	 * Triggered when the user clicks to edit the description
	
	
	
	 *
	 * @param {DescriptionEdited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	descriptionEdited,
	/**
	 * Triggered when the user select a entity type and search 
	
	
	
	 *
	 * @param {EntitySearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	entitySearched,
	/**
	 * Triggered when the user export the list of accounts.
	 *
	 * @param {ExportAccountList} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportAccountList,
	/**
	 * Triggered when the user dowload the export report.
	 *
	 * @param {ExportReportDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	exportReportDownloaded,
	/**
	 * Triggered when the user clicks on the "download" icon on the home page
	 *
	 * @param {FileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileDownloaded,
	/**
	 * Triggered when the user upload the file
	 *
	 * @param {FileUploaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	fileUploaded,
	/**
	 * Triggered when the user cancels the Manager permission journey by clicking on the "Cancel" button
	 *
	 * @param {ManagePermissionCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	managePermissionCanceled,
	/**
	 * Triggered when the user starts the Manager permission journey by clicking on the "Manage permission" button on the User Details Tab
	 *
	 * @param {ManagePermissionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	managePermissionStarted,
	/**
	 * Triggered when the user finishes to fill out the manage permission page and save changes
	 *
	 * @param {ManagerPermissionSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	managerPermissionSubmitted,
	/**
	 * Triggered when user cancels the new upload
	 *
	 * @param {NewUploadCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newUploadCanceled,
	/**
	 * Triggered when the user clicks on the "upload csv file" button
	 *
	 * @param {NewUploadStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newUploadStarted,
	/**
	 * Triggered when the upload process is finished and returns feedback (failure, partial success or success)
	 *
	 * @param {NewUploadSubmissionResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newUploadSubmissionResult,
	/**
	 * Triggered when the user clicks on the "upload" button in the modal
	 *
	 * @param {NewUploadSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newUploadSubmitted,
	/**
	 * Triggered when the user clicks on a  page number to view other a second page for example (1, 2, 3, 4, 5, 6, … , 10)
	 *
	 * @param {PageSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageSelected,
	/**
	 * When the user clicks on any page inside Identity Management FE
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * Triggered when the user accesses the Roles and Permissions Tab
	 *
	 * @param {PermissionsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	permissionsViewed,
	/**
	 * Triggered when the user select the new purchase experience option and clicks on the "Save Changes" button on the Change Settings Modal.
	 *
	 * @param {PurchaseExperienceSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	purchaseExperienceSubmitted,
	/**
	 * Triggered when the user clicks on the "pending" or the "completed" tab in the Recent Activities modal 
	
	 *
	 * @param {RecentActivitiesSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recentActivitiesSelected,
	/**
	 * Triggered when the user clicks on the Recent Activities button in the User Details page
	 *
	 * @param {RecentActivitiesViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	recentActivitiesViewed,
	/**
	 * Triggered when the user clicks on the Refresh button
	 *
	 * @param {RefreshedPage} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	refreshedPage,
	/**
	 * Triggered when the user clicks to remove the Admin access
	 *
	 * @param {RemoveAdminAccess} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	removeAdminAccess,
	/**
	 * Triggered when the user clicks to "remove scope" in the role details page
	
	
	 *
	 * @param {RemoveScopeFromRole} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	removeScopeFromRole,
	/**
	 * Triggered when the user click to cancel the remove scope from role
	
	 *
	 * @param {RemoveScopeFromRoleCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	removeScopeFromRoleCanceled,
	/**
	 * Triggered when the process of the Removing Scope from Role is finished and returns feedback (failure and/or success)
	
	
	
	
	
	 *
	 * @param {RemoveScopeFromRoleSubmissionResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	removeScopeFromRoleSubmissionResult,
	/**
	 * Triggered when the user click to "yes, remove" the scopes from the role in the modal
	
	
	
	 *
	 * @param {RemoveScopeFromRoleSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	removeScopeFromRoleSubmitted,
	/**
	 * Tiggered when the user clicks on User Details page
	 *
	 * @param {ReporterDetailsRedirection} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reporterDetailsRedirection,
	/**
	 * Tiggered when the user clicks on "copy email"
	 *
	 * @param {ReporterEmailCopy} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reporterEmailCopy,
	/**
	 * Triggered when the user clicks to resend invitation
	 *
	 * @param {ResendCustomerInvitation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	resendCustomerInvitation,
	/**
	 * Tiggered when the user clicks on the Resend Invitation
	 *
	 * @param {ResendUserInvitation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	resendUserInvitation,
	/**
	 * Triggered when the user click to create new scope
	
	 *
	 * @param {RoleCreationCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	roleCreationCanceled,
	/**
	 * Triggered when the process of the Role Creation is finished and returns feedback (failure and/or success)
	
	
	
	 *
	 * @param {RoleCreationResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	roleCreationResult,
	/**
	 * Triggered when the user click to create new scope
	 *
	 * @param {RoleCreationStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	roleCreationStarted,
	/**
	 * Triggered when the user click to create new role in the modal
	
	
	 *
	 * @param {RoleCreationSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	roleCreationSubmitted,
	/**
	 * Triggered when the user click to delete a role
	 *
	 * @param {RoleDeletion} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	roleDeletion,
	/**
	 * Triggered when the user click to cancel the scope creation
	
	 *
	 * @param {ScopeCreationCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	scopeCreationCanceled,
	/**
	 * Triggered when the process of the Scope Creation is finished and returns feedback (failure and/or success)
	
	
	
	 *
	 * @param {ScopeCreationResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	scopeCreationResult,
	/**
	 * Triggered when the user click to create new scope
	 *
	 * @param {ScopeCreationStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	scopeCreationStarted,
	/**
	 * Triggered when the user click to create new scope in the modal
	
	
	 *
	 * @param {ScopeCreationSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	scopeCreationSubmitted,
	/**
	 * Triggered when the user click to delete a scope
	 *
	 * @param {ScopeDeletion} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	scopeDeletion,
	/**
	 * Triggered when the user accesses the Bulk Action and select a tab
	
	
	 *
	 * @param {TabSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tabSelected,
	/**
	 * Triggered when the user clicks on the "download template" button on the home page
	 *
	 * @param {TemplateDownloadedHome} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	templateDownloadedHome,
	/**
	 * Triggered when the user clicks on the "download template" button on the modal
	 *
	 * @param {TemplateDownloadedModal} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	templateDownloadedModal,
	/**
	 * Triggered when user cancels user block
	 *
	 * @param {UserBlockCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userBlockCanceled,
	/**
	 * Triggered when the user status is blocked by an admin user
	 *
	 * @param {UserBlocked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userBlocked,
	/**
	 * Triggered when the user clicks on User Chart button and open the chart
	 *
	 * @param {UserChartViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userChartViewed,
	/**
	 * 
	Listing position the user was in when clicked
	 *
	 * @param {UserClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userClicked,
	/**
	 * Triggered when the Details tab of the User Details page loads
	 *
	 * @param {UserDetailsViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userDetailsViewed,
	/**
	 * 
	Triggered when the user cancels the User Edit Setup journey by clicking on the "Cancel" button on the Edit User Information Modal
	 *
	 * @param {UserEditingCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userEditingCanceled,
	/**
	 * Triggered when the user starts the User Edit Setup journey by clicking on the "Edit" button on the User Details Tab
	 *
	 * @param {UserEditingStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userEditingStarted,
	/**
	 * 
	Triggered when the User Edit process is finished and returns feedback (failure and/or success)
	 *
	 * @param {UserEditingSubmissionResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userEditingSubmissionResult,
	/**
	 * 
	Triggered when the user finishes to fill-out the user information forms and clicks on the "Save Changes" button on the Edit User Information Modal
	 *
	 * @param {UserEditingSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userEditingSubmitted,
	/**
	 * Triggered when the user clicks on the copy icon to copy the value
	 *
	 * @param {UserInfoCopied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInfoCopied,
	/**
	 * Triggered when the user clicks on the "X" button
	 *
	 * @param {UserInvitationCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInvitationCanceled,
	/**
	 * Triggered when the user selects the "One" or "force" option
	 *
	 * @param {UserInvitationCategorySelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInvitationCategorySelected,
	/**
	 * Triggered when the user clicks on the "Done" button
	 *
	 * @param {UserInvitationFinished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInvitationFinished,
	/**
	 * Triggered when the user clicks on the "Add new user" button
	 *
	 * @param {UserInvitationRestarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInvitationRestarted,
	/**
	 * Triggered when the process of the Admin User Invitation is finished and returns feedback (failure and/or success)
	 *
	 * @param {UserInvitationResult} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInvitationResult,
	/**
	 * Triggered when the user starts the User Invitation journey by clicking on the "Add User" button on the User List Tab
	 *
	 * @param {UserInvitationStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInvitationStarted,
	/**
	 * Triggered when the user clicks on the "finish" button
	 *
	 * @param {UserInvitationSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInvitationSubmitted,
	/**
	 * Triggered when the user accesses the User Inviation Tab
	 *
	 * @param {UserInvitationViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInvitationViewed,
	/**
	 * Triggered when the user clicks on "..." and select an action
	 *
	 * @param {UserListActions} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userListActions,
	/**
	 * Triggered when the user click to filter the user list
	 *
	 * @param {UserListFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userListFiltered,
	/**
	 * Triggered when the user scrolls down to the next page on the user list (only when the next pagination response will be loaded)
	 *
	 * @param {UserListPagination} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userListPagination,
	/**
	 * Triggered when the user selects terms to search a list based on a given criteria
	 *
	 * @param {UserListSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userListSearched,
	/**
	 * Triggered when the user click to show the user list
	 *
	 * @param {UserListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userListViewed,
	/**
	 * Triggered when the user starts the User Removal journey by clicking on the "Remove" button on the Account Details Tab
	 *
	 * @param {UserRemovalStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userRemovalStarted,
	/**
	 * Triggered when the user clicks on the "Remove" button on the User Removal Modal
	 *
	 * @param {UserRemovalSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userRemovalSubmitted,
	/**
	 * Triggered when the user selects terms to search a list based on a given criteria
	 *
	 * @param {UserSearchCriteria} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userSearchCriteria,
	/**
	 * Triggered when the user selects the user type
	 *
	 * @param {UserTypeSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userTypeSelected,
	/**
	 * Triggered when user cancels user unblock
	 *
	 * @param {UserUnblockCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userUnblockCanceled,
	/**
	 * Triggered when the user status is unblocked by an admin user
	 *
	 * @param {UserUnblocked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userUnblocked,
	/**
	 * Triggered when the user accesses the User Tab
	 *
	 * @param {UserViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userViewed,
	/**
	 * Triggered when the user click to extract the user list
	 *
	 * @param {UserExtractionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userExtractionStarted,
	/**
	 * Triggered when the user cancels the User Info Edit by clicking on the "cancel" button
	 *
	 * @param {UserInformationEditCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInformationEditCanceled,
	/**
	 * Triggered when the user starts the User Info Edit  by clicking on the "Edit user" button on the User Details Tab
	 *
	 * @param {UserInformationEditStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInformationEditStarted,
	/**
	 * Triggered when the user finishes to fill out the edit user page and save changes
	 *
	 * @param {UserInformationEditSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	userInformationEditSubmitted,
	/**
	 * Triggered when the user filters the list by wholesaler
	 *
	 * @param {WholesalerFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	wholesalerFiltered,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_28i2T5O8sjBR4ejrsll1XLvRohF`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
