import {
  IChartNavigation,
  IReporterData,
  ScreenName,
  UserApplications,
  ICountryFiltered,
  IUserClicked,
  IUserListViewed,
  IUserSearchCriteria,
  IUserCopyRegistrationLink,
  IResendInvitation,
  ILoggedAndTargetUser,
  IAccountRemovalSubmitted,
} from "./types";

import segmentAnalytics from "../../../shared/analytics";

export class AnalyticService {
  static chartNavigation = ({
    isManager,
    isReporter,
  }: IChartNavigation): void => {
    segmentAnalytics.chartNavigation({
      screen_name: ScreenName.UsersChart,
      manager_redirection: isManager,
      reporter_redirection: isReporter,
    });
  };

  static chartUserEmailCopied = ({ country, email }: IReporterData): void => {
    segmentAnalytics.reporterEmailCopy({
      screen_name: ScreenName.UsersChart,
      reporter_country: country,
      reporter_email: email,
    });
  };

  static chartUserDetailsRedirection = ({
    country,
    email,
  }: IReporterData): void => {
    segmentAnalytics.reporterDetailsRedirection({
      screen_name: ScreenName.UsersChart,
      reporter_country: country,
      reporter_email: email,
    });
  };

  static userViewedPage = (): void => {
    segmentAnalytics.userViewed({
      screen_name: ScreenName.Users,
    });
  };

  static userTypeSelected = (userType: UserApplications): void => {
    segmentAnalytics.userTypeSelected({
      screen_name: ScreenName.Users,
      user_type: userType,
    });
  };

  static countryFiltered({ country, userType }: ICountryFiltered): void {
    segmentAnalytics.countryFiltered({
      country,
      user_type: userType,
      screen_name: ScreenName.Users,
    });
  }

  static userListViewed({
    country,
    information,
    listedItems,
    searchCriteria,
    userType,
  }: IUserListViewed): void {
    segmentAnalytics.userListViewed({
      country,
      information,
      listed_items: listedItems,
      search_criteria: searchCriteria,
      user_type: userType,
      screen_name: ScreenName.Users,
    });
  }

  static userClicked({ clickedItemPosition, listedItems }: IUserClicked): void {
    segmentAnalytics.userClicked({
      clicked_item_position: clickedItemPosition,
      listed_items: listedItems,
      screen_name: ScreenName.Users,
    });
  }

  static userSearchCriteria({
    searchCriteria,
    userType,
  }: IUserSearchCriteria): void {
    segmentAnalytics.userSearchCriteria({
      screen_name: ScreenName.Users,
      search_criteria: searchCriteria,
      user_type: userType,
    });
  }

  static userDetailsViewed({
    adminAffiliation,
    adminPermission,
    country,
    screenName,
    userAffiliation,
    userApp,
    userEmail,
    userId,
    userPhone,
    userStatus,
  }: ILoggedAndTargetUser): void {
    segmentAnalytics.userDetailsViewed({
      admin_affiliation: adminAffiliation,
      admin_permission: adminPermission,
      country,
      screen_name: screenName,
      user_affiliation: userAffiliation,
      user_app: userApp,
      user_email: userEmail,
      user_id: userId,
      user_phone: userPhone,
      user_status: userStatus,
    });
  }

  static userInfoEditStarted(): void {
    segmentAnalytics.userInformationEditStarted({
      screen_name: ScreenName.UserDetails,
    });
  }

  static userInfoEditSubmitted({
    adminAffiliation,
    adminPermission,
    country,
    screenName,
    userAffiliation,
    userApp,
    userId,
  }: Omit<
    ILoggedAndTargetUser,
    "userPhone" | "userEmail" | "userStatus"
  >): void {
    segmentAnalytics.userInformationEditSubmitted({
      admin_affiliation: adminAffiliation,
      admin_permission: adminPermission,
      country,
      screen_name: screenName,
      user_affiliation: userAffiliation,
      user_app: userApp,
      user_id: userId,
    });
  }

  static userChartViewed(): void {
    segmentAnalytics.userChartViewed({
      screen_name: ScreenName.UsersChart,
    });
  }

  static userCopyRegistrationLink({
    country,
    screenName,
    userApp,
    userId,
  }: IUserCopyRegistrationLink): void {
    segmentAnalytics.copyUserRegistrationLink({
      country,
      screen_name: screenName,
      user_app: userApp,
      user_id: userId,
    });
  }

  static resendInvitation({
    country,
    screenName,
    userApp,
    userId,
  }: IResendInvitation): void {
    segmentAnalytics.resendUserInvitation({
      country,
      screen_name: screenName,
      user_app: userApp,
      user_id: userId,
    });
  }

  static userBlocked({
    adminAffiliation,
    adminPermission,
    country,
    screenName,
    userAffiliation,
    userApp,
    userEmail,
    userId,
    userPhone,
    userStatus,
  }: ILoggedAndTargetUser): void {
    segmentAnalytics.userBlocked({
      admin_affiliation: adminAffiliation,
      admin_permission: adminPermission,
      country,
      screen_name: screenName,
      user_affiliation: userAffiliation,
      user_app: userApp,
      user_email: userEmail,
      user_id: userId,
      user_phone: userPhone,
      user_status: userStatus,
    });
  }

  static userUnblocked({
    adminAffiliation,
    adminPermission,
    country,
    screenName,
    userAffiliation,
    userApp,
    userEmail,
    userId,
    userPhone,
    userStatus,
  }: ILoggedAndTargetUser): void {
    segmentAnalytics.userUnblocked({
      admin_affiliation: adminAffiliation,
      admin_permission: adminPermission,
      country,
      screen_name: screenName,
      user_affiliation: userAffiliation,
      user_app: userApp,
      user_email: userEmail,
      user_id: userId,
      user_phone: userPhone,
      user_status: userStatus,
    });
  }

  static managePermissionsStarted(): void {
    segmentAnalytics.managePermissionStarted({
      screen_name: ScreenName.UserDetails,
    });
  }

  static managePermissionSubmitted({
    adminAffiliation,
    adminPermission,
    country,
    screenName,
    userAffiliation,
    userApp,
    userEmail,
    userId,
    userPhone,
    userStatus,
  }: ILoggedAndTargetUser): void {
    segmentAnalytics.managerPermissionSubmitted({
      admin_affiliation: adminAffiliation,
      admin_permission: adminPermission,
      country,
      screen_name: screenName,
      user_affiliation: userAffiliation,
      user_app: userApp,
      user_email: userEmail,
      user_id: userId,
      user_phone: userPhone,
      user_status: userStatus,
    });
  }

  static accountRemovalSubmitted({
    associatedAccounts,
    country,
    screenName,
    selectedAccounts,
    userApp,
    userId,
  }: IAccountRemovalSubmitted): void {
    segmentAnalytics.accountRemovalSubmitted({
      associated_accounts: associatedAccounts,
      country,
      screen_name: screenName,
      selected_accounts: selectedAccounts,
      user_app: userApp,
      user_id: userId,
    });
  }
}
