import { Radio } from "@hexa-ui/components";
import { Download as DownloadIcon } from "@hexa-ui/icons";
import {
  useHasPermission,
  useUserMetadata,
  Vendor,
} from "admin-portal-shared-services";
import { SingleSelect } from "components/SingleSelect";
import { SegmentService } from "helpers";
import { BatchFilesActionType } from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import React, { useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { FileInputs } from "./FileInputs";
import {
  Alert,
  Content,
  CsvLinkButtonContainer,
  CustomerUploaderWrapper,
  InfoMessage,
  SelectTypeGroup,
  SelectTypeLabel,
  AlertMessageContainer,
} from "./styles";
import { CustomerUserActionType, IUploaderFileProps } from "./types";

interface ICustomerUserUploadFile extends IUploaderFileProps {
  actionType?: CustomerUserActionType;
  setActionType: (description: CustomerUserActionType) => void;
}

export const CustomerUserUploadFile = ({
  handleOnChangeFile,
  setFileDescription,
  file,
  onClearFileField,
  fileUploadError,
  actionType,
  setActionType,
}: ICustomerUserUploadFile): JSX.Element => {
  const [selectedVendorId, setSelectedVendorId] = useState<string>();
  const { data, isLoading } = useUserMetadata();
  const vendors: Vendor[] = data?.vendors ?? [];
  const vendorOptions = vendors.map((vendor: Vendor) => ({
    label: vendor.displayName ?? "",
    value: vendor.id ?? "",
  }));

  const optionActionTypeRadio: {
    label: string;
    value: CustomerUserActionType;
    hasPermission: boolean;
  }[] = [
    {
      label: "Customer user",
      value: "CUSTOMER_USER" as CustomerUserActionType,
      hasPermission: useHasPermission("IdentityFE.UserPOC.Write"),
    },
    {
      label: "Link user",
      value: "LINK_USER" as CustomerUserActionType,
      hasPermission: useHasPermission("IdentityFE.CustomerUser.Write"),
    },
  ].filter((option) => option.hasPermission);

  const handleCompanyChange = (vendorId: string) => {
    setSelectedVendorId(vendorId);
  };

  const generateCSV = (): string[][] => {
    const vendor = vendors?.find(
      (vendorInstance) => vendorInstance.id === selectedVendorId
    );
    if (!vendor || !actionType) return [];

    const CSVContentCostumerUser: string[][] = [
      [
        "userName",
        "user",
        "country",
        "vendorAccountId",
        "vendorId",
        "vendorName",
        "action",
      ],
      [
        "name 1",
        "e-mail or phone",
        vendor.country ?? "",
        "vendorAccountId value",
        vendor.id ?? "",
        vendor.displayName ?? "",
        "ADD",
      ],
      [
        "name 2",
        "e-mail or phone",
        vendor.country ?? "",
        "vendorAccountId value",
        vendor.id ?? "",
        vendor.displayName ?? "",
        "REMOVE",
      ],
    ];

    const CSVContentLinkUser: string[][] = [
      [
        "userName",
        "user",
        "country",
        "vendorAccountId",
        "vendorIdFromSeller",
        "vendorIdFromManufacturer",
        "vendorName",
        "permissionGroup",
        "action",
      ],
      [
        "name 1",
        "e-mail or phone",
        vendor.country ?? "",
        "vendorAccountId value",
        "vendorId value",
        vendor.id ?? "",
        vendor.displayName ?? "",
        "LinkAdmin.Example",
        "ADD",
      ],
      [
        "name 2",
        "e-mail or phone",
        vendor.country ?? "",
        "vendorAccountId value",
        "vendorId value",
        vendor.id ?? "",
        vendor.displayName ?? "",
        "LinkAdmin.Example",
        "REMOVE",
      ],
    ];

    const CSVContent = {
      CUSTOMER_USER: CSVContentCostumerUser,
      LINK_USER: CSVContentLinkUser,
    };

    return CSVContent[actionType];
  };

  const isDownloadEnabled = useMemo(() => {
    return selectedVendorId && actionType;
  }, [actionType, selectedVendorId]);

  const registerSegmentEvent = () => {
    SegmentService.bulkActionsTemplateDownloadedModal(
      actionType === "CUSTOMER_USER"
        ? BatchFilesActionType.ManageCustomerUser
        : BatchFilesActionType.ManageLinkUser,
      selectedVendorId
    );
  };

  const disabledSelectCompany = vendorOptions.length === 0 || isLoading;

  return (
    <Content>
      <InfoMessage size="small">
        You must select a company and type to download the template.
      </InfoMessage>
      <CustomerUploaderWrapper>
        <SingleSelect
          label="Select Company"
          placeholder="Company name"
          options={vendorOptions}
          value={selectedVendorId}
          onChange={handleCompanyChange}
          disabled={disabledSelectCompany}
        />

        <SelectTypeLabel size="H5">Select type</SelectTypeLabel>
        <SelectTypeGroup
          onValueChange={(value: CustomerUserActionType) => {
            setActionType(value);
          }}
          value={actionType}
        >
          <React.Fragment key=".0">
            {optionActionTypeRadio.map((actionTypeOption) => (
              <Radio.Item
                key={actionTypeOption.value}
                id={actionTypeOption.value}
                label={actionTypeOption.label}
                value={actionTypeOption.value}
              />
            ))}
          </React.Fragment>
        </SelectTypeGroup>
        {actionType === "LINK_USER" && (
          <AlertMessageContainer>
            <Alert
              type="warning"
              message="This user will have restricted access to contracts in the account they are associated with based on the company ID."
            />
          </AlertMessageContainer>
        )}

        <CsvLinkButtonContainer>
          <CSVLink
            enclosingCharacter=""
            data={generateCSV()}
            aria-disabled={!isDownloadEnabled}
            tabIndex={isDownloadEnabled ? 0 : -1}
            onClick={registerSegmentEvent}
          >
            <DownloadIcon fr="true" size="medium" />
            Download template
          </CSVLink>
        </CsvLinkButtonContainer>
      </CustomerUploaderWrapper>

      <FileInputs
        handleOnChangeFile={handleOnChangeFile}
        setFileDescription={setFileDescription}
        file={file}
        fileUploadError={fileUploadError}
        onClearFileField={onClearFileField}
      />
    </Content>
  );
};
