import segmentAnalytics, {
  AccountDeselection,
  AccountRemovalStarted,
  AccountRemovalSubmissionResult,
  AccountRemovalSubmitted,
  AccountSelection,
  AllAccountDeselection,
  AllAccountSelection,
  ApplicationsItem,
  CountryFiltered,
  NewUploadSubmissionResult,
  PageSelected,
  UserBlocked,
  UserClicked,
  UserDetailsViewed,
  UserEditingSubmissionResult,
  UserEditingSubmitted,
  UserListViewed,
  UserSearchCriteria,
  UserUnblocked,
} from "analytics";
import {
  CopiedCriteria,
  FileType,
  FilterCriteria,
  ListType,
  ScreenName,
  ScreenName1,
  TabName,
  UserType,
  BatchFilesActionType,
} from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import { INewUploadSubmitted, IUserData } from "./types";

export class SegmentService {
  static countryFiltered(countryFiltered: CountryFiltered): void {
    segmentAnalytics.countryFiltered(countryFiltered);
  }

  static userViewed(): void {
    segmentAnalytics.userViewed({ screen_name: ScreenName.Users });
  }

  static userInvitationStarted(): void {
    segmentAnalytics.userInvitationStarted({ screen_name: ScreenName.Users });
  }

  static userBlocked(userBlocked: UserBlocked): void {
    segmentAnalytics.userBlocked(userBlocked);
  }

  static userClicked(userClicked: UserClicked): void {
    segmentAnalytics.userClicked(userClicked);
  }

  static userSearchCriteria(userSearchCriteria: UserSearchCriteria): void {
    segmentAnalytics.userSearchCriteria(userSearchCriteria);
  }

  static userListViewed(userListViewed: UserListViewed): void {
    segmentAnalytics.userListViewed(userListViewed);
  }

  static userInfoCopied(copiedCriteria: CopiedCriteria): void {
    segmentAnalytics.userInfoCopied({
      screen_name: ScreenName.UserDetails,
      copied_criteria: copiedCriteria,
    });
  }

  static userUnblockCanceled(wasCancelButtonPressed: boolean): void {
    segmentAnalytics.userUnblockCanceled({
      cancel_button: wasCancelButtonPressed,
      screen_name: ScreenName.UserDetails,
    });
  }

  static userBlockCanceled(wasCancelButtonPressed: boolean): void {
    segmentAnalytics.userBlockCanceled({
      cancel_button: wasCancelButtonPressed,
      screen_name: ScreenName.UserDetails,
    });
  }

  static userDetailsViewed(userDetailsViewed: UserDetailsViewed): void {
    segmentAnalytics.userDetailsViewed(userDetailsViewed);
  }

  static userUnblocked(userUnblocked: UserUnblocked): void {
    segmentAnalytics.userUnblocked(userUnblocked);
  }

  static userAccountSelection(accountClicked: AccountSelection): void {
    segmentAnalytics.accountSelection(accountClicked);
  }

  static userAccountDeselection(accountClicked: AccountDeselection): void {
    segmentAnalytics.accountDeselection(accountClicked);
  }

  static accountRemovalCanceled(): void {
    segmentAnalytics.accountRemovalCanceled({
      screen_name: ScreenName.UserDetails,
    });
  }

  static accountRemovalStarted(
    accountRemovalStarted: Omit<AccountRemovalStarted, "screen_name">
  ): void {
    segmentAnalytics.accountRemovalStarted({
      ...accountRemovalStarted,
      screen_name: ScreenName.UserDetails,
    });
  }

  static accountRemovalSubmitted(
    accountRemovalSubmitted: AccountRemovalSubmitted
  ): void {
    segmentAnalytics.accountRemovalSubmitted(accountRemovalSubmitted);
  }

  static accountRemovalSubmissionResult(
    accountRemovalSubmissionResult: AccountRemovalSubmissionResult
  ): void {
    segmentAnalytics.accountRemovalSubmissionResult(
      accountRemovalSubmissionResult
    );
  }

  static userEditingStarted(): void {
    segmentAnalytics.userEditingStarted({
      screen_name: ScreenName.UserDetails,
    });
  }

  static userEditingSubmitted(
    userEditingSubmitted: UserEditingSubmitted
  ): void {
    segmentAnalytics.userEditingSubmitted(userEditingSubmitted);
  }

  static userEditingCanceled(): void {
    segmentAnalytics.userEditingCanceled({
      screen_name: ScreenName.UserDetails,
    });
  }

  static userEditingSubmissionResult(
    userEditingSubmissionResult: UserEditingSubmissionResult
  ): void {
    segmentAnalytics.userEditingSubmissionResult(userEditingSubmissionResult);
  }

  static allAccountSelection(trackingInfo: AllAccountSelection): void {
    segmentAnalytics.allAccountSelection(trackingInfo);
  }

  static allAccountDeselection(trackingInfo: AllAccountDeselection): void {
    segmentAnalytics.allAccountDeselection(trackingInfo);
  }

  static userTypeSelected(userType: UserType): void {
    segmentAnalytics.userTypeSelected({
      screen_name: ScreenName.Users,
      user_type: userType,
    });
  }

  static applicationFiltered(applications: ApplicationsItem[] | null): void {
    segmentAnalytics.applicationFiltered({
      filter_criteria: FilterCriteria.Application,
      list_type: ListType.Users,
      screen_name: ScreenName.Users,
      applications,
    });
  }

  static bulkActionsTemplateDownloadedHome(fileType: FileType): void {
    segmentAnalytics.templateDownloadedHome({
      screen_name: ScreenName.Bulk,
      file_type: fileType,
    });
  }

  static bulkActionsTemplateDownloadedModal(
    fileType: BatchFilesActionType,
    vendorId?: string
  ): void {
    segmentAnalytics.templateDownloadedModal({
      screen_name: ScreenName.Bulk,
      file_type: fileType,
      vendor_id: vendorId ?? "",
    });
  }

  static bulkActionsFileDownloaded(fileType: FileType): void {
    segmentAnalytics.fileDownloaded({
      screen_name: ScreenName.Bulk,
      file_type: fileType,
    });
  }

  static bulkActionsPageSelected(pageSelected: PageSelected): void {
    segmentAnalytics.pageSelected(pageSelected);
  }

  static bulkActionsRefreshedPage(fileType: FileType): void {
    segmentAnalytics.refreshedPage({
      screen_name: ScreenName.Bulk,
      file_type: fileType,
    });
  }

  static bulkActionsViewed(filesCount: number, fileType: FileType): void {
    segmentAnalytics.bulkActionsViewed({
      screen_name: ScreenName.Bulk,
      listed_items: filesCount,
      file_type: fileType,
    });
  }

  static newUploadStarted(fileType: FileType): void {
    segmentAnalytics.newUploadStarted({
      screen_name: ScreenName.Bulk,
      file_type: fileType,
    });
  }

  static fileUploaded(
    hasBeenUploaded: boolean,
    fileType: BatchFilesActionType | null
  ): void {
    segmentAnalytics.fileUploaded({
      screen_name: ScreenName.Bulk,
      browse_button: hasBeenUploaded,
      file_type: fileType,
    });
  }

  static newUploadedCanceled(
    hasBeenCanceled: boolean,
    fileType: BatchFilesActionType | null
  ): void {
    segmentAnalytics.newUploadCanceled({
      screen_name: ScreenName.Bulk,
      cancel_button: hasBeenCanceled,
      file_type: fileType,
    });
  }

  static newUploadSubmitted({
    fileTitle,
    fileDescription,
    rowsNumber,
    fileType,
    vendorId,
  }: INewUploadSubmitted): void {
    segmentAnalytics.newUploadSubmitted({
      screen_name: ScreenName.Bulk,
      title_name: fileTitle,
      description: fileDescription,
      rows_number: rowsNumber,
      file_type: fileType,
      vendor_id: vendorId ?? "",
    });
  }

  static newUploadSubmissionResult(
    submissioData: NewUploadSubmissionResult
  ): void {
    segmentAnalytics.newUploadSubmissionResult(submissioData);
  }

  static tabSelected(fileType: FileType, listedItems: number): void {
    segmentAnalytics.tabSelected({
      screen_name: ScreenName1.Bulk1,
      file_type: fileType,
      listed_items: listedItems,
    });
  }

  static recentActivitiesViewed(): void {
    segmentAnalytics.recentActivitiesViewed({
      screen_name: ScreenName1.UserDetails1,
    });
  }

  static recentActivitiesTabSelected(tabName: TabName): void {
    segmentAnalytics.recentActivitiesSelected({
      screen_name: ScreenName1.UserDetails1,
      tab_name: tabName,
    });
  }

  static resendUserInvitation({
    country,
    userApplication,
    userId,
  }: IUserData): void {
    segmentAnalytics.resendUserInvitation({
      screen_name: ScreenName1.UserDetails1,
      country,
      user_app: userApplication,
      user_id: userId,
    });
  }

  static userChartViewed(): void {
    segmentAnalytics.userChartViewed({
      screen_name: ScreenName.UsersChart,
      direct_reporters: null,
    });
  }

  static copyUserRegistrationLink({
    country,
    userApplication,
    userId,
  }: IUserData): void {
    segmentAnalytics.copyUserRegistrationLink({
      screen_name: ScreenName1.UserDetails1,
      country,
      user_app: userApplication,
      user_id: userId,
    });
  }
}
